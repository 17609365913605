import { combineReducers } from 'redux';

import selfservice, { SelfServiceState } from '../../views/selfservice/SelfServiceReducer';
import { apiBase } from '../api/Api.utils';

const rootReducer = combineReducers({
  api: apiBase.reducer,
  selfservice,
});

export interface IGlobalState {
  selfservice: SelfServiceState;
}

export default rootReducer;
