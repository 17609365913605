import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { apiBase } from '../api/Api.utils';
import rootReducer, { IGlobalState } from './rootReducer';

const createStore = () => {
  const storeInstance = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // TODO remove when fully refactored to redux toolkit
      }).concat(apiBase.middleware),
  });

  if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
    (module as any).hot.accept('./rootReducer', () => storeInstance.replaceReducer(rootReducer as any));
  }

  setupListeners(storeInstance.dispatch);

  return storeInstance;
};

const store = createStore();
export default store;

export type IAppDispatch = typeof store.dispatch;
export type IRootState = ReturnType<typeof store.getState>;

// TODO remove when fully refactored to redux toolkit
export type DispatchThunk = ThunkDispatch<IGlobalState, undefined, AnyAction>;
