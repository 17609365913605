interface IEnvironmentValues {
  production?: string;
  test?: string;
  default?: string;
}

/**
 * Depending on the environment, return specific value
 */
export const getEnvironmentSpecificValue = (values: IEnvironmentValues) => {
  const nodeEnv = process.env.NODE_ENV;

  if (values.production && nodeEnv === 'production') {
    return values.production || '';
  } else if (values.test && nodeEnv === 'test') {
    return values.test || '';
  } else {
    return values.default || '';
  }
};

/**
 * If site is served inside TalTech mobile app webview
 */
export const isApp = navigator.userAgent.toLowerCase().indexOf('taltech-mobile-app') >= 0;
export const isAppEt = navigator.userAgent.toLowerCase().indexOf('taltech-mobile-app-et') >= 0;
export const isAppEn = navigator.userAgent.toLowerCase().indexOf('taltech-mobile-app-en') >= 0;
