import ApiClient from './AxiosClient';
import SelfServiceController from './controllers/self-service/SelfServiceController';

export class ApiService {
  public selfservice: SelfServiceController;

  constructor() {
    this.selfservice = new SelfServiceController(ApiClient);
  }
}

const api = new ApiService();
export default api;
