import React from 'react';
import { Route } from 'react-router-dom';

import { FEATURE_FLAGS } from '../../constants/featureFlags';
import RouteGuard from './RouteGuard';

export type ICustomRouteProps<Path = string | string[]> = (
  | {
      component: JSX.Element;
      children?: ICustomRouteProps[];
    }
  | {
      /**
       * Component to render for matching path. If omitted then renders null
       */
      component?: JSX.Element;
      /**
       * If path has children routes. Parent route must have a component prop that contains <Outlet>
       */
      children?: never;
    }
) & {
  path: Path;
  /**
   * If route is allowed for only specific roles
   */
  roleLock?: {
    roles: any[];
    /**
     * Default fallback route is root(/)
     */
    fallbackRoute?: string;
  };
  featureFlagLock?: {
    flag: FEATURE_FLAGS;
    /**
     * Default fallback route is root(/)
     */
    fallbackRoute?: string;
  };
  /**
   * Will redirect the user to unauthorized view
   */
  contractLock?: boolean;
};

const renderCustomRoute = ({ index, ...route }: ICustomRouteProps & { index: number }) => {
  const renderSingleRoute = ({ index, ...route }: ICustomRouteProps<string> & { index?: number }) => {
    return (
      <Route key={index} path={route.path} element={<RouteGuard route={route} />}>
        {route.children?.map((childRoute, childIndex) => renderCustomRoute({ ...childRoute, index: childIndex }))}
      </Route>
    );
  };

  if (Array.isArray(route.path)) {
    return (
      <React.Fragment key={index}>
        {route.path.map((path, subIndex) => renderSingleRoute({ ...route, path: path, index: subIndex }))}
      </React.Fragment>
    );
  } else {
    return renderSingleRoute({ ...route, path: route.path, index: index });
  }
};

export default renderCustomRoute;
