import axios from 'axios';
import { toast } from 'taltech-styleguide';

import { IOldTableRequest } from '../../common/api/GlobalTypes';
import { DispatchThunk } from '../../common/store/storeConfig';
import i18nInstance from '../../common/translation/i18n';
import { loadableDataActions } from '../../common/utils/LoadableData';
import api from '../../services/Api.services';
import {
  IAccountantBusinessTripsResponse,
  IManagerAppraisalsRequest,
  IManagerAppraisalsResponse,
  IManagerBusinessTripsResponse,
  IManagerExpensesRequest,
  IManagerExpensesResponse,
  IManagerInvoicesRequest,
  IManagerInvoicesResponse,
  IManagerSecondmentsResponse,
  IMyAppraisalsResponse,
  IMyBusinessTripsRequest,
  IMyBusinessTripsResponse,
  IMyExpensesResponse,
  IMyInvoicesResponse,
  IMySecondmentsResponse,
  IMyServicesResponse,
  MANAGER_SECONDMENT_STATUS,
} from '../../services/controllers/self-service/SelfServiceTypes';

const ns = 'selfservice/';

/* MY TAB ACTIONS */
export const LoadMySecondmentsActions = loadableDataActions<void, IMySecondmentsResponse>(`${ns}GET_MY_SECONDMENTS`);
export const LoadMyBusinessTripsActions = loadableDataActions<void, IMyBusinessTripsResponse>(
  `${ns}GET_MY_BUSINESS_TRIPS`
);
export const LoadMyExpensesActions = loadableDataActions<void, IMyExpensesResponse>(`${ns}GET_MY_EXPENSES`);
export const LoadMyServicesActions = loadableDataActions<void, IMyServicesResponse>(`${ns}GET_MY_SERVICES`);
export const LoadMyAppraisalsActions = loadableDataActions<void, IMyAppraisalsResponse>(`${ns}GET_MY_APPRAISALS`);
export const LoadMyInvoicesActions = loadableDataActions<void, IMyInvoicesResponse>(`${ns}GET_MY_INVOICES`);

/* MANAGER TAB ACTIONS */
export const LoadManagerSecondmentsActions = loadableDataActions<void, IManagerSecondmentsResponse>(
  `${ns}GET_MANAGER_SECONDMENTS`
);
export const LoadManagerBusinessTripsActions = loadableDataActions<void, IManagerBusinessTripsResponse>(
  `${ns}GET_MANAGER_BUSINESS_TRIPS`
);
export const LoadManagerExpensesActions = loadableDataActions<void, IManagerExpensesResponse>(
  `${ns}GET_MANAGER_EXPENSES`
);
export const LoadManagerAppraisalsActions = loadableDataActions<void, IManagerAppraisalsResponse>(
  `${ns}GET_MANAGER_APPRAISALS`
);
export const LoadManagerInvoicesActions = loadableDataActions<void, IManagerInvoicesResponse>(
  `${ns}GET_MANAGER_INVOICES`
);
/* ACCOUNTANT TAB ACTIONS */
export const LoadAccountantBusinessTripsActions = loadableDataActions<void, IAccountantBusinessTripsResponse>(
  `${ns}GET_ACCOUNTANT_BUSINESS_TRIPS`
);

export function loadMySecondments() {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadMySecondmentsActions.request(undefined));
      const response = await api.selfservice.getMySecondments();
      dispatch(LoadMySecondmentsActions.success(response));
    } catch (e) {
      if (!axios.isCancel(e)) {
        toast.error(i18nInstance.t('selfservice:secondments-widget.loading-error'));
        dispatch(LoadMySecondmentsActions.error(e));
      }
    }
  };
}

export function loadMyBusinessTrips(data: IMyBusinessTripsRequest) {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadMyBusinessTripsActions.request(undefined));
      const response = await api.selfservice.getMyBusinessTrips(data);
      dispatch(LoadMyBusinessTripsActions.success(response));
    } catch (e) {
      if (!axios.isCancel(e)) {
        toast.error(i18nInstance.t('selfservice:business-trips-widget.loading-error'));
        dispatch(LoadMyBusinessTripsActions.error(e));
      }
    }
  };
}

export function loadMyExpenses() {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadMyExpensesActions.request(undefined));
      const response = await api.selfservice.getMyExpenses();
      dispatch(LoadMyExpensesActions.success(response));
    } catch (e) {
      toast.error(i18nInstance.t('selfservice:expenses-widget.loading-error'));
      dispatch(LoadMyExpensesActions.error(e));
    }
  };
}

export function loadMyServices() {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadMyServicesActions.request(undefined));
      const response = await api.selfservice.getMyServices();
      dispatch(LoadMyServicesActions.success(response));
    } catch (e) {
      toast.error(i18nInstance.t('selfservice:service-requests-widget.loading-error'));
      dispatch(LoadMyServicesActions.error(e));
    }
  };
}

export function loadMyAppraisals() {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadMyAppraisalsActions.request(undefined));
      const response = await api.selfservice.getMyAppraisals();
      dispatch(LoadMyAppraisalsActions.success(response));
    } catch (e) {
      toast.error(i18nInstance.t('selfservice:appraisals-widget.loading-error'));
      dispatch(LoadMyAppraisalsActions.error(e));
    }
  };
}

export function loadMyInvoices() {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadMyInvoicesActions.request(undefined));
      const response = await api.selfservice.getMyInvoices();
      dispatch(LoadMyInvoicesActions.success(response));
    } catch (e) {
      toast.error(i18nInstance.t('selfservice:invoices-widget.loading-error'));
      dispatch(LoadMyInvoicesActions.error(e));
    }
  };
}

export function loadManagerSecondments(data: IOldTableRequest<MANAGER_SECONDMENT_STATUS[]>) {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadManagerSecondmentsActions.request(undefined));
      const response = await api.selfservice.getManagerSecondments(data);
      dispatch(LoadManagerSecondmentsActions.success(response));
    } catch (e) {
      toast.error(i18nInstance.t('selfservice:secondments-widget.loading-error'));
      dispatch(LoadManagerSecondmentsActions.error(e));
    }
  };
}

export function loadManagerBusinessTrips() {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadManagerBusinessTripsActions.request(undefined));
      const response = await api.selfservice.getManagerBusinessTrips();
      dispatch(LoadManagerBusinessTripsActions.success(response));
    } catch (e) {
      toast.error(i18nInstance.t('selfservice:business-trips-widget.loading-error'));
      dispatch(LoadManagerBusinessTripsActions.error(e));
    }
  };
}

export function loadManagerExpenses(data: IManagerExpensesRequest) {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadManagerExpensesActions.request(undefined));
      const response = await api.selfservice.getManagerExpenses(data);
      dispatch(LoadManagerExpensesActions.success(response));
    } catch (e) {
      toast.error(i18nInstance.t('selfservice:expenses-widget.loading-error'));
      dispatch(LoadManagerExpensesActions.error(e));
    }
  };
}

export function loadManagerAppraisals(data: IManagerAppraisalsRequest) {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadManagerAppraisalsActions.request(undefined));
      const response = await api.selfservice.getManagerAppraisals(data);
      dispatch(LoadManagerAppraisalsActions.success(response));
    } catch (e) {
      toast.error(i18nInstance.t('selfservice:appraisals-widget.loading-error'));
      dispatch(LoadManagerAppraisalsActions.error(e));
    }
  };
}

export function loadManagerInvoices(data: IManagerInvoicesRequest) {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadManagerInvoicesActions.request(undefined));
      const response = await api.selfservice.getManagerInvoices(data);
      dispatch(LoadManagerInvoicesActions.success(response));
    } catch (e) {
      toast.error(i18nInstance.t('selfservice:invoices-widget.loading-error'));
      dispatch(LoadManagerInvoicesActions.error(e));
    }
  };
}

export function loadAccountantBusinessTrips() {
  return async (dispatch: DispatchThunk) => {
    try {
      dispatch(LoadAccountantBusinessTripsActions.request(undefined));
      const response = await api.selfservice.getAccountantBusinessTrips();
      dispatch(LoadAccountantBusinessTripsActions.success(response));
    } catch (e) {
      toast.error(i18nInstance.t('selfservice:business-trips-widget.loading-error'));
      dispatch(LoadAccountantBusinessTripsActions.error(e));
    }
  };
}
