import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getOrigin } from '../../utils/urlsUtil';

/**
 * When no internal route match, then redirect to external url
 * Used for relative urls that come from APIs but are not react urls
 */
const ExternalRedirect = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const origin = getOrigin();
    const redirectURL = origin + pathname.replace(`${process.env.PUBLIC_URL}/`, '/');
    window.location.replace(redirectURL);
  }, [pathname]);

  return null;
};

export default React.memo(ExternalRedirect);
