import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import { apiBase } from '../../../common/api/Api.utils';
import { IBaseResponse } from '../../../common/api/GlobalTypes';
import { ROLES } from '../../../common/auth/AuthTypes';
import i18nInstance from '../../../common/translation/i18n';
import { blobToFileDownload } from '../../../common/utils/fileUtils';
import {
  IAddLeaveRequest,
  IAddLeaveRequestNew,
  IAnnualLeaveValidationRequest,
  IAnnualLeaveValidationResponse,
  ICalculateDaysRequest,
  ICalculateDaysResponse,
  IConfirmLeaveRequest,
  IDeleteLeaveAttachmentRequest,
  IDeleteLeaveAttachmentResponse,
  IDownloadLeaveAttachmentRequest,
  IGetLeaveAttachmentsRequest,
  IGetLeaveAttachmentsResponse,
  IHrDepartmentOverviewRequest,
  IHrDepartmentOverviewResponse,
  IHrLeaveOverviewRequest,
  IHrLeaveOverviewResponse,
  ILeaveConfirmScheduleRequest,
  ILeaveConfirmScheduleResponse,
  ILeaveDetailsRequest,
  ILeaveDetailsResponse,
  ILeaveHistoryRequest,
  ILeaveHistoryResponse,
  ILeaveOverviewRequest,
  ILeaveOverviewResponse,
  ILeaveRemaindersRequest,
  ILeaveRemaindersResponse,
  ILeaveScheduleDepartmentRequest,
  ILeaveScheduleDepartmentResponse,
  ILeaveScheduleModeResponse,
  ILeaveScheduleRequest,
  ILeaveScheduleResponse,
  ILeaveScheduleStatusResponse,
  ILeaveSubstitutesRequest,
  ILeaveSubstitutesResponse,
  ILeaveTypesResponse,
  IManagerDepartmentOverviewRequest,
  IManagerDepartmentOverviewResponse,
  IManagerLeaveOverviewRequest,
  IManagerLeaveOverviewResponse,
  IMultiPeriodAnnualLeaveValidationRequest,
  IMultiPeriodAnnualLeaveValidationResponse,
  INewAddLeaveResponse,
  INewCalculateDaysRequest,
  IRejectLeaveRequest,
  ISetSchedulePreConfirmRequest,
  ISubordinateRequest,
  ISubordinateResponse,
  ITeachingFacultiesDto,
  ITeachingFacultiesResponse,
  IUpdateLeaveRequest,
  IUpdateMultiPeriodLeaveRequest,
  IWithdrawLeaveRequest,
} from './LeaveTypes';

export const LEAVE_CACHE_TAGS = {
  OVERVIEW_LIST: 'Overview_list',
  REMAINDERS_LIST: 'Remainders',
  SCHEDULE_MODE: 'Schedule_mode',
  LEAVE_DAYS: 'Leave_days',
  MULTI_PERIOD_LEAVE_DAYS: 'Multi_period_leave_days',
  HR_DEPARTMENT_OVERVIEW_LIST: 'Hr_department_overview_list',
  MANAGER_DEPARTMENT_OVERVIEW_LIST: 'Manager_department_overview_list',
  MANAGER_LEAVE_OVERVIEW: 'Manager_leave_overview',
  MANAGER_SCHEDULE_LIST: 'Manager_schedule_list',
  SCHEDULE_STATUS: 'Schedule_status',
  HR_SCHEDULE_LIST: 'Hr_schedule_list',
  HR_DEPARTMENT_LEAVE_OVERVIEW: 'Hr_department_leave_overview',
  LEAVE_DETAILS: 'Leave_details',
  SUBSTITUTES: 'Substitutes',
  TEACHING_FACULTIES: 'Teaching_faculties',
} as const;

export const leaveApi = apiBase
  .enhanceEndpoints({
    addTagTypes: Object.values(LEAVE_CACHE_TAGS),
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLeaveOverview: builder.query<ILeaveOverviewResponse, ILeaveOverviewRequest>({
        providesTags: [LEAVE_CACHE_TAGS.OVERVIEW_LIST],
        query: (request) => {
          const apiUrl = request?.id !== undefined ? 'getUserVacations' : 'getMyVacations';
          return {
            url: `/vacations-api/v1/vacations/${apiUrl}`,
            method: 'POST',
            body: request,
          };
        },
      }),
      getLeaveScheduleMode: builder.query<ILeaveScheduleModeResponse, void>({
        providesTags: [LEAVE_CACHE_TAGS.SCHEDULE_MODE],
        query: () => ({
          url: `/vacations-api/v1/vacations/getLeaveScheduleMode`,
          method: 'GET',
        }),
      }),
      getScheduleStatus: builder.query<ILeaveScheduleStatusResponse, void>({
        providesTags: [LEAVE_CACHE_TAGS.SCHEDULE_STATUS],
        query: () => ({
          url: `/vacations-api/v1/vacations/getScheduleStatus`,
          method: 'POST',
        }),
        extraOptions: {
          onErrorString: () => i18nInstance.t('leave:leave-schedule.status-load-error'),
        },
      }),
      confirmSchedule: builder.mutation<ILeaveConfirmScheduleResponse, ILeaveConfirmScheduleRequest>({
        invalidatesTags: [
          LEAVE_CACHE_TAGS.SCHEDULE_STATUS,
          LEAVE_CACHE_TAGS.MANAGER_SCHEDULE_LIST,
          LEAVE_CACHE_TAGS.HR_SCHEDULE_LIST,
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/confirmSchedule`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onErrorString: () => i18nInstance.t('leave:leave-schedule.confirm-error'),
        },
      }),
      getManagerScheduleList: builder.query<ILeaveScheduleResponse, ILeaveScheduleRequest>({
        providesTags: [LEAVE_CACHE_TAGS.MANAGER_SCHEDULE_LIST],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/getScheduleOverview`,
          method: 'POST',
          body: request,
        }),
      }),
      getHrScheduleList: builder.query<ILeaveScheduleResponse, ILeaveScheduleRequest>({
        providesTags: [LEAVE_CACHE_TAGS.HR_SCHEDULE_LIST],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/getScheduleHrOverview`,
          method: 'POST',
          body: request,
        }),
      }),
      getManagerScheduleDepartment: builder.query<ILeaveScheduleDepartmentResponse, ILeaveScheduleDepartmentRequest>({
        query: (request) => ({
          url: `/vacations-api/v1/vacations/getScheduleEmployeesOverview`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onErrorString: () => i18nInstance.t('leave:leave-schedule.loading-error'),
        },
      }),
      setSchedulePreConfirm: builder.mutation<IBaseResponse, ISetSchedulePreConfirmRequest>({
        query: (request) => ({
          url: `/vacations-api/v1/vacations/setScedulePreConfirm`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onErrorString: () => i18nInstance.t('leave:leave-schedule.pre-confirmation-error'),
        },
      }),
      getManagerLeaveOverview: builder.query<IManagerLeaveOverviewResponse, IManagerLeaveOverviewRequest>({
        providesTags: [LEAVE_CACHE_TAGS.MANAGER_LEAVE_OVERVIEW],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/getManagerVacations`,
          method: 'POST',
          body: request,
        }),
      }),
      getManagerDepartmentOverview: builder.query<
        IManagerDepartmentOverviewResponse,
        IManagerDepartmentOverviewRequest
      >({
        providesTags: [LEAVE_CACHE_TAGS.MANAGER_DEPARTMENT_OVERVIEW_LIST],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/getManagerDepartmentOverview`,
          method: 'POST',
          body: request,
        }),
      }),
      getHrLeaveOverview: builder.query<IHrLeaveOverviewResponse, IHrLeaveOverviewRequest>({
        providesTags: [LEAVE_CACHE_TAGS.HR_DEPARTMENT_LEAVE_OVERVIEW],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/getHRVacations`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onErrorString: () => i18nInstance.t('selfservice:leave-widget.loading-error'),
        },
      }),
      getHrDepartmentOverview: builder.query<IHrDepartmentOverviewResponse, IHrDepartmentOverviewRequest>({
        providesTags: [LEAVE_CACHE_TAGS.HR_DEPARTMENT_OVERVIEW_LIST],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/getHRDepartmentOverview`,
          method: 'POST',
          body: request,
        }),
      }),
      getLeaveDetails: builder.query<ILeaveDetailsResponse, number>({
        providesTags: (result) => [
          LEAVE_CACHE_TAGS.LEAVE_DETAILS,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: result?.id },
        ],
        query: (leaveId: number) => {
          const request: ILeaveDetailsRequest = { id: leaveId };

          return {
            url: `/vacations-api/v1/vacations/getVacation/`,
            method: 'POST',
            body: request,
          };
        },
      }),
      getLeaveRemainders: builder.query<ILeaveRemaindersResponse, string | void>({
        providesTags: [LEAVE_CACHE_TAGS.REMAINDERS_LIST],
        query: (employeeId?: string) => {
          const apiUrl = employeeId !== undefined ? 'getUserRemainders' : 'getMyRemainders';

          const request: ILeaveRemaindersRequest = employeeId !== undefined ? { id: employeeId } : {};

          return {
            url: `/vacations-api/v1/vacations/${apiUrl}`,
            method: 'POST',
            body: request,
          };
        },
      }),
      getLeaveHistory: builder.query<ILeaveHistoryResponse, number>({
        query: (leaveId: number) => {
          const request: ILeaveHistoryRequest = { id: leaveId };
          return {
            url: `/vacations-api/v1/vacations/getVacationHistory`,
            method: 'POST',
            body: request,
          };
        },
      }),
      getWorkersLeaveReport: builder.query<string, void>({
        async queryFn(req, api, extraOptions, fetchWithBQ) {
          try {
            const response = (await fetchWithBQ({
              url: `/vacations-api/v1/vacations/exportVacationsOverview`,
              method: 'GET',
              cache: 'no-cache',
              responseHandler: (response) => response.blob(),
            })) as QueryReturnValue<string, FetchBaseQueryError>;

            if (response && (response.data as any) instanceof Blob) {
              blobToFileDownload(response.data, 'puhkuste_aruanne', 'xlsx');
              return { data: '' };
            }
          } catch (e) {}

          return { data: '' };
        },
        extraOptions: {
          onErrorString: () => i18nInstance.t('leave:download.errors.failed-download'),
        },
      }),
      validateLeave: builder.query<IAnnualLeaveValidationResponse, IAnnualLeaveValidationRequest>({
        query: (request) => {
          return {
            url: `/vacations-api/v1/vacations/validateVacation`,
            method: 'POST',
            body: request,
          };
        },
        extraOptions: {
          onErrorString: () => i18nInstance.t('leave:form.validation.remote-failed'),
        },
      }),
      newValidateLeave: builder.query<
        IMultiPeriodAnnualLeaveValidationResponse,
        IMultiPeriodAnnualLeaveValidationRequest
      >({
        query: (request) => {
          return {
            url: `/vacations-api/v1/vacations/validateVacationArray`,
            method: 'POST',
            body: request,
          };
        },
      }),
      calculateLeaveDays: builder.query<ICalculateDaysResponse, ICalculateDaysRequest>({
        providesTags: [LEAVE_CACHE_TAGS.LEAVE_DAYS],
        query: (request) => {
          return {
            url: `/vacations-api/v1/vacations/calculateVacationDays`,
            method: 'POST',
            body: request,
          };
        },
      }),
      newCalculateLeaveDays: builder.query<ICalculateDaysResponse, INewCalculateDaysRequest>({
        providesTags: [LEAVE_CACHE_TAGS.MULTI_PERIOD_LEAVE_DAYS],
        query: (request) => {
          return {
            url: `/vacations-api/v1/vacations/calculateVacationDaysArray`,
            method: 'POST',
            body: request,
          };
        },
      }),
      addLeave: builder.mutation<IBaseResponse, IAddLeaveRequest>({
        invalidatesTags: [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/addVacation`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.submit-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.submit-failed'),
        },
      }),
      newAddLeave: builder.mutation<INewAddLeaveResponse, IAddLeaveRequestNew>({
        invalidatesTags: [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/addVacation`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.submit-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.submit-failed'),
        },
      }),
      updateLeave: builder.mutation<IBaseResponse, IUpdateLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/updateVacation`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.update-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.update-failed'),
        },
      }),
      updateConfirmedLeave: builder.mutation<IBaseResponse, IUpdateMultiPeriodLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/changeConfirmedVacation`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.update-submitted-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.update-submitted-failed'),
        },
      }),
      confirmLeave: builder.mutation<IBaseResponse, IConfirmLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.HR_DEPARTMENT_LEAVE_OVERVIEW,
          LEAVE_CACHE_TAGS.MANAGER_LEAVE_OVERVIEW,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/confirmVacation`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.confirmation-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.confirmation-failed'),
        },
      }),
      confirmLeaveWithdrawal: builder.mutation<IBaseResponse, IConfirmLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.HR_DEPARTMENT_LEAVE_OVERVIEW,
          LEAVE_CACHE_TAGS.MANAGER_LEAVE_OVERVIEW,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/confirmWithdrawal`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.withdraw-confirmation-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.withdraw-confirmation-failed'),
        },
      }),
      confirmLeaveChange: builder.mutation<IBaseResponse, IConfirmLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.HR_DEPARTMENT_LEAVE_OVERVIEW,
          LEAVE_CACHE_TAGS.MANAGER_LEAVE_OVERVIEW,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/confirmChange`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.change-confirmation-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.change-confirmation-failed'),
        },
      }),
      rejectLeave: builder.mutation<IBaseResponse, IRejectLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.HR_DEPARTMENT_LEAVE_OVERVIEW,
          LEAVE_CACHE_TAGS.MANAGER_LEAVE_OVERVIEW,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/rejectVacation`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.reject-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.reject-failed'),
        },
      }),
      rejectLeaveWithdrawal: builder.mutation<IBaseResponse, IRejectLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.HR_DEPARTMENT_LEAVE_OVERVIEW,
          LEAVE_CACHE_TAGS.MANAGER_LEAVE_OVERVIEW,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/rejectWithdrawal`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.withdraw-reject-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.withdraw-reject-failed'),
        },
      }),
      rejectLeaveChange: builder.mutation<IBaseResponse, IRejectLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.HR_DEPARTMENT_LEAVE_OVERVIEW,
          LEAVE_CACHE_TAGS.MANAGER_LEAVE_OVERVIEW,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/rejectChange`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.change-reject-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.change-reject-failed'),
        },
      }),
      withdrawPendingLeave: builder.mutation<IBaseResponse, IWithdrawLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.HR_DEPARTMENT_LEAVE_OVERVIEW,
          LEAVE_CACHE_TAGS.MANAGER_LEAVE_OVERVIEW,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/withdrawPendingVacation`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.withdraw-pending-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.withdraw-pending-failed'),
        },
      }),
      withdrawPendingChangeLeave: builder.mutation<IBaseResponse, IWithdrawLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.HR_DEPARTMENT_LEAVE_OVERVIEW,
          LEAVE_CACHE_TAGS.MANAGER_LEAVE_OVERVIEW,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/withdrawPendingChange`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.change-withdraw-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.change-withdraw-failed'),
        },
      }),
      withdrawConfirmedLeave: builder.mutation<IBaseResponse, IWithdrawLeaveRequest>({
        invalidatesTags: (result, error, arg) => [
          LEAVE_CACHE_TAGS.OVERVIEW_LIST,
          LEAVE_CACHE_TAGS.REMAINDERS_LIST,
          LEAVE_CACHE_TAGS.SCHEDULE_MODE,
          LEAVE_CACHE_TAGS.HR_DEPARTMENT_LEAVE_OVERVIEW,
          LEAVE_CACHE_TAGS.MANAGER_LEAVE_OVERVIEW,
          { type: LEAVE_CACHE_TAGS.LEAVE_DETAILS, id: arg.id },
        ],
        query: (request) => ({
          url: `/vacations-api/v1/vacations/withdrawConfirmedVacation`,
          method: 'POST',
          body: request,
        }),
        extraOptions: {
          onSuccessString: () => i18nInstance.t('leave:applications.notifications.withdraw-confirmed-successful'),
          onErrorString: () => i18nInstance.t('leave:applications.notifications.withdraw-confirmed-failed'),
        },
      }),
      getLeaveTypes: builder.query<ILeaveTypesResponse, void>({
        query: () => ({
          url: `/vacations-api/v1/vacations/getLeaveTypes`,
          method: 'GET',
        }),
      }),
      getSubstitutes: builder.query<ILeaveSubstitutesResponse, ILeaveSubstitutesRequest>({
        providesTags: [LEAVE_CACHE_TAGS.SUBSTITUTES],
        query: (request) => {
          const queryRequest = {
            filters: request.filters,
            pagination: {
              number: 0,
              size: 10,
            },
            sort: {
              column: 'personName',
              direction: 'asc',
            },
          };

          return {
            url: `${process.env.REACT_APP_API_BASE_URL}/nav_ws/contract`,
            method: 'POST',
            body: queryRequest,
          };
        },
      }),
      getSubordinates: builder.query<ISubordinateResponse, ISubordinateRequest>({
        query: (request) => {
          if (!request.myProfile) return '';

          let filters = { ...request?.filters };

          const shouldSkipCodeFilters = request.myProfile?.roles.includes(ROLES.HR);

          if (request.myProfile?.roles.includes(ROLES.MANAGER) && !shouldSkipCodeFilters) {
            filters = { ...filters, ouCode: request.myProfile.isManagerOf };
          }

          if (request.myProfile?.roles.includes(ROLES.MANAGER_ASSISTANT) && !shouldSkipCodeFilters) {
            filters = { ...filters, ouCode: request.myProfile.isManagerAssistantOf };
          }

          if (request.myProfile?.roles.includes(ROLES.SUB_DIV_MANAGER) && !shouldSkipCodeFilters) {
            filters = { ...filters, dvCode: request.myProfile.isSubdivManagerOf };
          }

          const queryRequest = {
            filters: { ...filters },
            pagination: {
              number: 0,
              size: 10,
            },
            sort: {
              column: 'personName',
              direction: 'asc',
            },
          };

          return {
            url: `${process.env.REACT_APP_API_BASE_URL}/nav_ws/contract`,
            method: 'POST',
            body: queryRequest,
          };
        },
      }),
      getLeaveAttachments: builder.query<IGetLeaveAttachmentsResponse, IGetLeaveAttachmentsRequest>({
        query: (request) => ({
          url: `/vacations-api/v1/vacations/attachments/${request.id}`,
          method: 'GET',
        }),
      }),
      deleteLeaveAttachment: builder.mutation<IDeleteLeaveAttachmentResponse, IDeleteLeaveAttachmentRequest>({
        query: (request) => ({
          url: `/vacations-api/v1/vacations/deleteAttachment/${request.id}/${request.hash}`,
          method: 'DELETE',
        }),
        extraOptions: {
          onErrorString: () => i18nInstance.t('leave:applications.notifications.remove-attachment-failed'),
        },
      }),
      downloadLeaveAttachment: builder.query<string, IDownloadLeaveAttachmentRequest>({
        async queryFn(req, api, extraOptions, fetchWithBQ) {
          try {
            const response = (await fetchWithBQ({
              url: `/vacations-api/v1/vacations/downloadAttachment/${req.id}/${req.hash}`,
              method: 'GET',
              responseHandler: (response) => response.blob(),
            })) as QueryReturnValue<string, FetchBaseQueryError>;

            if (response && (response.data as any) instanceof Blob) {
              blobToFileDownload(response.data, req.fileName);
              return { data: '' };
            }
          } catch (e) {}
          return { data: '' };
        },
      }),
      getTeachingFaculties: builder.query<ITeachingFacultiesDto[], void>({
        providesTags: [LEAVE_CACHE_TAGS.TEACHING_FACULTIES],
        async queryFn(req, api, extraOptions, fetchWithBQ) {
          try {
            const response = (await fetchWithBQ({
              url: `${process.env.REACT_APP_API_BASE_URL}/nav_ws/structure/faculties`,
              method: 'GET',
            })) as QueryReturnValue<ITeachingFacultiesResponse, FetchBaseQueryError>;

            let teachingFaculties: ITeachingFacultiesDto[] = [];
            if (response && response.data && response.data.result) {
              teachingFaculties = response.data.result;
            }

            return {
              data: teachingFaculties,
            };
          } catch (e) {}
          return { data: [] };
        },
      }),
    }),
  });

export const {
  useGetLeaveOverviewQuery,
  useLazyGetLeaveOverviewQuery,
  useGetLeaveScheduleModeQuery,
  useLazyGetLeaveScheduleModeQuery,
  useGetScheduleStatusQuery,
  useLazyGetScheduleStatusQuery,
  useGetManagerScheduleListQuery,
  useLazyGetManagerScheduleListQuery,
  useLazyValidateLeaveQuery,
  useLazyNewValidateLeaveQuery,
  useGetHrScheduleListQuery,
  useLazyGetHrScheduleListQuery,
  useGetManagerScheduleDepartmentQuery,
  useLazyGetManagerScheduleDepartmentQuery,
  useGetManagerLeaveOverviewQuery,
  useLazyGetManagerLeaveOverviewQuery,
  useGetManagerDepartmentOverviewQuery,
  useLazyGetManagerDepartmentOverviewQuery,
  useGetHrLeaveOverviewQuery,
  useLazyGetHrLeaveOverviewQuery,
  useGetHrDepartmentOverviewQuery,
  useLazyGetHrDepartmentOverviewQuery,
  useCalculateLeaveDaysQuery,
  useLazyCalculateLeaveDaysQuery,
  useLazyNewCalculateLeaveDaysQuery,
  useGetLeaveDetailsQuery,
  useLazyGetLeaveDetailsQuery,
  useGetLeaveRemaindersQuery,
  useLazyGetLeaveRemaindersQuery,
  useGetLeaveHistoryQuery,
  useLazyGetLeaveHistoryQuery,
  useGetWorkersLeaveReportQuery,
  useLazyGetWorkersLeaveReportQuery,
  useUpdateLeaveMutation,
  useConfirmLeaveMutation,
  useConfirmLeaveChangeMutation,
  useConfirmLeaveWithdrawalMutation,
  useWithdrawPendingLeaveMutation,
  useWithdrawConfirmedLeaveMutation,
  useWithdrawPendingChangeLeaveMutation,
  useRejectLeaveMutation,
  useRejectLeaveChangeMutation,
  useRejectLeaveWithdrawalMutation,
  useAddLeaveMutation,
  useNewAddLeaveMutation,
  useUpdateConfirmedLeaveMutation,
  useConfirmScheduleMutation,
  useSetSchedulePreConfirmMutation,
  useGetLeaveTypesQuery,
  useLazyGetSubstitutesQuery,
  useGetSubordinatesQuery,
  useLazyGetSubordinatesQuery,
  useLazyGetTeachingFacultiesQuery,
  useLazyGetLeaveAttachmentsQuery,
  useLazyDownloadLeaveAttachmentQuery,
  useDeleteLeaveAttachmentMutation,
} = leaveApi;
