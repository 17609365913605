import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_COLOR, ButtonGroup, Loader, Modal } from 'taltech-styleguide';

import type { IConfirmationModalProps } from './ConfirmationModal.hook';

interface IConfirmationModalContext {
  addModal: (props: IConfirmationModalProps) => void;
  updateModal: (props: Partial<IConfirmationModalProps>) => void;
  closeModal: (id: string) => void;
  getModal: (id: string) => IConfirmationModalProps | undefined;
}

export const ConfirmationModalContext = React.createContext<IConfirmationModalContext>({
  addModal: () => {},
  updateModal: () => {},
  closeModal: () => {},
  getModal: () => undefined,
});

export const ConfirmationModalProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const [modalList, setModalList] = useState<IConfirmationModalProps[]>([]);

  const addModal = useCallback((props: IConfirmationModalProps) => {
    setModalList((prevState) => (!prevState.find((s) => s.id === props.id) ? [...prevState, { ...props }] : prevState));
  }, []);

  const updateModal = useCallback(({ id, ...props }: Partial<IConfirmationModalProps>) => {
    setModalList((prevState) => prevState.map((s) => (s.id === id ? { ...s, ...props } : s)));
  }, []);

  const removeModal = useCallback((id: string) => {
    setModalList((prevState) => prevState.filter((s) => s.id !== id));
  }, []);

  const closeModal = useCallback((id: string) => {
    setModalList((prevState) => prevState.map((s) => (s.id === id ? { ...s, isOpen: false } : s)));
  }, []);

  const getModal = useCallback(
    (id: string) => {
      return modalList.find((m) => m.id === id);
    },
    [modalList]
  );

  const handleConfirm = useCallback(
    (id: string) => {
      modalList.find((modal) => modal.id === id)?.confirmCallback?.();
      getModal(id)?.closeOnConfirmCallback && closeModal(id);
    },
    [closeModal, getModal, modalList]
  );

  const handleCancel = useCallback(
    (id: string) => {
      modalList.find((modal) => modal.id === id)?.cancelCallback?.();
      closeModal(id);
    },
    [closeModal, modalList]
  );

  return (
    <ConfirmationModalContext.Provider value={{ addModal, getModal, closeModal, updateModal }}>
      {modalList?.map((modal, index) => (
        <Modal
          key={index}
          show={modal.isOpen}
          onHide={() => closeModal(modal.id)}
          onExited={() => removeModal(modal.id)}
          centered
          backdrop={modal.isLoading ? 'static' : undefined}
          keyboard={!modal.isLoading}
          {...modal.options}
        >
          {modal.isLoading && <Loader overlay />}
          <Modal.Header>{modal.title ?? t('general:confirmation-title')}</Modal.Header>
          {modal.description && <Modal.Body>{modal.description}</Modal.Body>}
          <Modal.Footer>
            <ButtonGroup>
              <Button variant={BUTTON_COLOR.PRIMARY} onClick={() => handleConfirm(modal.id)}>
                {modal.confirmLabel ?? t('general:yes')}
              </Button>
              <Button variant={BUTTON_COLOR.LINK} onClick={() => handleCancel(modal.id)}>
                {modal.cancelLabel ?? t('general:no')}
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      ))}

      {children}
    </ConfirmationModalContext.Provider>
  );
};
