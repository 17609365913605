import * as Sentry from '@sentry/browser';
import axios from 'axios';

import { acquireAccessToken } from '../common/auth/azureAuth';

const axiosInstance = axios.create({
  baseURL: '',
  timeout: 60000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const { scopes, anonymous, ...rest } = config?.params || {};

    try {
      // for certain requests we don't want to pass an Authorization header
      if (!anonymous) {
        const token = await acquireAccessToken(scopes?.length ? { scopes } : undefined);
        if (token && config?.headers) {
          config.headers['Authorization'] = `Bearer ${token.accessToken}`;
        }
      }
      config.params = rest;
    } catch (e) {
      console.log('Error', e);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // check if response is actually a JSON
    if (response?.headers['content-type']?.includes('application/json')) {
      try {
        JSON.parse(response.request.response);
      } catch (e) {
        console.error(`${response.config.url} parse error`, e);
        Sentry.withScope((scope) => {
          scope.setTag('api_error', 'not_json');
          scope.setExtra('api_endpoint', response.config.url);
          scope.setExtra('api_data', response.request.responseText.slice(0, 100));
          Sentry.captureException(e);
        });
      }
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;
