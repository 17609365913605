import { useMsal } from '@azure/msal-react';
import * as Sentry from '@sentry/browser';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header, IHelpProps, IProfileProps, ISearchProps } from 'taltech-styleguide';

import avatarPlaceholder from '../../assets/avatar_placeholder.svg';
import { useFeatureFlag, useWhoAmIDto } from '../../common/auth/Auth.hooks';
import { useSetLangMutation } from '../../common/auth/AuthApiStore';
import { logOut } from '../../common/auth/AuthStore';
import { authParameters } from '../../common/auth/azureAuth';
import { FEATURE_FLAGS } from '../../common/constants/featureFlags';
import { paths } from '../../common/router/routeMap';
import { useGetMenuQuery, useLazyGetFaqQuery, useLazyGetHelpQuery } from '../../common/store/GeneralApiStore';
import { formPost, getOrigin } from '../../common/utils/urlsUtil';
import { IAnchorProps, LinkBehaviour } from '../anchor/Anchor';

const TalTechHeader: React.FC = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { whoAmIDto } = useWhoAmIDto();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');
  const [slogan, setSlogan] = useState('');
  const isUserSwitchAllowed = useFeatureFlag(FEATURE_FLAGS.ALLOW_USER_SWITCH);
  const isSearchAllowed = useFeatureFlag(FEATURE_FLAGS.SEARCH_FLOW);
  const { data } = useGetMenuQuery(undefined, { skip: !whoAmIDto });
  const [changeLang] = useSetLangMutation({
    fixedCacheKey: 'track-language-change',
  });
  const [loadHelp, { data: helpDto, isLoading: helpDtoLoading }] = useLazyGetHelpQuery();
  const [loadFaq, { data: faqDto, isLoading: faqDtoLoading }] = useLazyGetFaqQuery();

  const links = useMemo(() => data?.links || [], [data?.links]);

  const randomizeSlogan = useCallback(() => {
    const newSlogan = data?.slogans?.length ? data?.slogans[Math.floor(Math.random() * data?.slogans?.length)] : '';
    setSlogan(newSlogan);
  }, [data?.slogans]);

  const handleHelpClick = useCallback(() => {
    // TODO get module and focus based on route
    loadHelp('main');
    loadFaq('main');
  }, [loadFaq, loadHelp]);

  const handleSearch = useCallback(
    (searchText: string) => {
      if (isSearchAllowed) {
        navigate(`${paths.search()}?all=${searchText}`);
        setSearchString('');
      } else {
        formPost(`${getOrigin()}/search`, {
          search_string: searchText,
        });
      }
    },
    [isSearchAllowed, navigate]
  );

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e?.target?.value || '');
  }, []);

  const handleUserSwitch = useCallback(() => {
    instance.setActiveAccount(null);
    instance.loginRedirect({ ...authParameters, prompt: 'select_account' }).catch((e) => {
      Sentry.withScope((scope) => {
        scope.setTag('auth_error', 'user_switch_redirect_error');
        Sentry.captureException(e);
      });
    });
  }, [instance]);

  const searchObject: ISearchProps = useMemo(
    () => ({
      placeholder: t('header.search.placeholder'),
      value: searchString,
      onChange: handleSearchChange,
      onSearch: handleSearch,
    }),
    [handleSearch, handleSearchChange, searchString, t]
  );

  const helpObject: IHelpProps = useMemo(
    () => ({
      help: helpDto || '',
      faq: faqDto || '',
      loading: helpDtoLoading || faqDtoLoading,
      onClick: handleHelpClick,
      faqTab: t('general:header.help.faq'),
      helpTab: t('general:header.help.help'),
    }),
    [faqDto, faqDtoLoading, handleHelpClick, helpDto, helpDtoLoading, t]
  );

  const profileObject: IProfileProps<typeof LinkBehaviour> = useMemo(
    () => ({
      profile: {
        firstName: whoAmIDto?.givenName,
        lastName: whoAmIDto?.Sn,
        photo: whoAmIDto?.avatar || avatarPlaceholder,
      },
      onLogout: logOut,
      onLanguageSwitch: () => changeLang(),
      onSwitchUser: isUserSwitchAllowed ? handleUserSwitch : undefined,
      links: [{ children: t('general:header.profile.profile'), to: paths.profile.root() }],
    }),
    [changeLang, handleUserSwitch, isUserSwitchAllowed, t, whoAmIDto?.Sn, whoAmIDto?.avatar, whoAmIDto?.givenName]
  );

  const logoLink: IAnchorProps = useMemo(
    () => ({
      as: LinkBehaviour,
      to: '/',
      children: t('general:brand'),
    }),
    [t]
  );

  const skipLink: IAnchorProps<'a'> = useMemo(
    () => ({
      href: '#main-content-id',
      children: t('general:skip'),
    }),
    [t]
  );

  return (
    <Header
      logoLink={logoLink}
      skipLink={skipLink}
      linkAs={LinkBehaviour}
      links={links}
      onLevel3Open={randomizeSlogan}
      slogan={slogan}
      search={searchObject}
      help={helpObject}
      profile={profileObject}
    />
  );
};

export default React.memo(TalTechHeader);
