import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import i18n from 'i18next';
import { FILTER_TYPES, SORT_ORDER } from 'taltech-styleguide';

import { IOldTableRequest } from '../../../common/api/GlobalTypes';
import { LANGUAGES } from '../../../common/constants/locale';
import {
  APPRAISALS_STATUS,
  BusinessTripReportStatusEnum,
  EXPENSES_STATUS,
  IAccountantBusinessTripsResponse,
  IManagerAppraisalsRequest,
  IManagerAppraisalsResponse,
  IManagerBusinessTripsResponse,
  IManagerExpensesRequest,
  IManagerExpensesResponse,
  IManagerInvoicesRequest,
  IManagerInvoicesResponse,
  IManagerSecondmentsRequest,
  IManagerSecondmentsResponse,
  IMyAppraisalsRequest,
  IMyAppraisalsResponse,
  IMyBusinessTripsRequest,
  IMyBusinessTripsResponse,
  IMyExpensesRequest,
  IMyExpensesResponse,
  IMyInvoicesRequest,
  IMyInvoicesResponse,
  IMySecondmentsRequest,
  IMySecondmentsResponse,
  IMyServicesRequest,
  IMyServicesResponse,
  INVOICE_STATUS,
  MANAGER_APPRAISALS_STATUS,
  MANAGER_EXPENSES_STATUS,
  MANAGER_SECONDMENT_STATUS,
  SECONDMENT_STATUS,
  SERVICES_STATUS,
} from './SelfServiceTypes';

export default class SelfServiceController {
  // cancel tokens that allow us to cancel requests mid flight
  mySecondmentsToken: CancelTokenSource = axios.CancelToken.source();

  constructor(private api: AxiosInstance) {}

  getMySecondments = async (data?: Partial<IMySecondmentsRequest>): Promise<IMySecondmentsResponse> => {
    const request: IMySecondmentsRequest = {
      filters: [
        {
          field: 'statusCode',
          type: FILTER_TYPES.MULTISELECT,
          value: [
            SECONDMENT_STATUS.STATUS_REVIEWING,
            SECONDMENT_STATUS.STATUS_ACCEPTED,
            SECONDMENT_STATUS.STATUS_REJECTED,
          ],
        },
      ],
      lang: { value: i18n.language as LANGUAGES },
      sort: {
        direction: SORT_ORDER.ASC,
        column: 'date_from',
      },
      pagination: {
        size: 50,
      },
      ...data,
    };

    if (this.mySecondmentsToken) {
      this.mySecondmentsToken.cancel();
    }

    this.mySecondmentsToken = axios.CancelToken.source(); // reset cancel token for this request

    return this.api.post(`/v1/CA8QL9/mission`, request, { cancelToken: this.mySecondmentsToken.token });
  };

  getMyBusinessTrips = async (data?: Partial<IMyBusinessTripsRequest>): Promise<IMyBusinessTripsResponse> => {
    return this.api.get(`/business-trips-api/business-trips?type=upcoming&employeeUniId=${data?.employee_uni_id}`);
  };

  getMyInvoices = async (): Promise<IMyInvoicesResponse> => {
    const request: IMyInvoicesRequest = {
      filters: [
        {
          field: 'statusCode',
          type: FILTER_TYPES.MULTISELECT,
          value: [
            INVOICE_STATUS.STATUS_ADDED,
            INVOICE_STATUS.STATUS_SENT,
            INVOICE_STATUS.STATUS_REVIEWING,
            INVOICE_STATUS.STATUS_ACCEPTED,
            INVOICE_STATUS.STATUS_REJECTED,
          ],
        },
      ],
      sort: {
        direction: SORT_ORDER.ASC,
        column: 'created',
      },
      pagination: {
        size: 50,
      },
    };

    return this.api.post(`/v1/CA8QL9/invoices`, request);
  };

  getMyExpenses = async (): Promise<IMyExpensesResponse> => {
    // when changing these filters then it is required to change the filters in MyExpensesWidget.tsx
    const request: IMyExpensesRequest = {
      filters: [
        {
          field: 'statusCode',
          type: FILTER_TYPES.MULTISELECT,
          value: [
            EXPENSES_STATUS.STATUS_ADDED,
            EXPENSES_STATUS.STATUS_REVIEWING,
            EXPENSES_STATUS.STATUS_REJECTED,
            EXPENSES_STATUS.STATUS_ACCEPTED,
          ],
        },
      ],
      pagination: {
        size: 50,
      },
    };

    return this.api.post(`/v1/CA8QL9/fc`, request);
  };

  getMyServices = async (): Promise<IMyServicesResponse> => {
    const request: IMyServicesRequest = {
      filters: [
        {
          field: 'statusCode',
          type: FILTER_TYPES.MULTISELECT,
          value: [
            SERVICES_STATUS.STATUS_COMMITED,
            SERVICES_STATUS.STATUS_SOLVING,
            SERVICES_STATUS.STATUS_WAITING,
            SERVICES_STATUS.STATUS_CLOSED,
          ],
        },
      ],
      pagination: {
        size: 50,
      },
    };

    return this.api.post(`/v1/CA8QL9/servicereq`, request);
  };

  getMyAppraisals = async (): Promise<IMyAppraisalsResponse> => {
    const request: IMyAppraisalsRequest = {
      filters: [
        {
          field: 'statusCode',
          type: FILTER_TYPES.MULTISELECT,
          value: [
            APPRAISALS_STATUS.STATUS_STARTED,
            APPRAISALS_STATUS.STATUS_SENT,
            APPRAISALS_STATUS.STATUS_EMPLOYEE,
            APPRAISALS_STATUS.STATUS_COMPLETED,
          ],
        },
      ],
      pagination: {
        size: 50,
      },
    };

    return this.api.post(`/v1/CA8QL9/appraisal`, request);
  };

  getManagerSecondments = async (
    data?: IOldTableRequest<MANAGER_SECONDMENT_STATUS[]>
  ): Promise<IManagerSecondmentsResponse> => {
    const request: IManagerSecondmentsRequest = {
      ...data,
      filters: [
        {
          field: 'statusCode',
          type: FILTER_TYPES.MULTISELECT,
          value: [MANAGER_SECONDMENT_STATUS.STATUS_APPROVING],
        },
      ],
      lang: { value: i18n.language as LANGUAGES },
      sort: {
        direction: SORT_ORDER.ASC,
        column: 'date_from',
      },
    };

    return this.api.post(`/v1/CA8QL9/manage_mission`, request);
  };

  getManagerBusinessTrips = async (): Promise<IManagerBusinessTripsResponse> => {
    return this.api.get('/business-trips-api/business-trips?type=upcoming');
  };

  getManagerExpenses = async (data?: IManagerExpensesRequest): Promise<IManagerExpensesResponse> => {
    const request: IManagerExpensesRequest = {
      ...data,
      filters: [
        {
          field: 'statusCode',
          type: FILTER_TYPES.MULTISELECT,
          value: [MANAGER_EXPENSES_STATUS.STATUS_APPROVING],
        },
      ],
      sort: {
        direction: SORT_ORDER.ASC,
        column: 'created',
      },
    };

    return this.api.post(`/v1/CA8QL9/manage_fc`, request);
  };

  getManagerAppraisals = async (data?: IManagerAppraisalsRequest): Promise<IManagerAppraisalsResponse> => {
    const request: IManagerAppraisalsRequest = {
      ...data,
      filters: [
        {
          field: 'statusCode',
          type: FILTER_TYPES.MULTISELECT,
          value: [MANAGER_APPRAISALS_STATUS.STATUS_APPROVING],
        },
      ],
      sort: {
        direction: SORT_ORDER.ASC,
        column: 'created',
      },
    };

    return this.api.post(`/v1/CA8QL9/manage_appraisal`, request);
  };

  getManagerInvoices = async (data: IManagerInvoicesRequest): Promise<IManagerInvoicesResponse> => {
    const request: IManagerInvoicesRequest = {
      ...data,
      filters: [
        {
          field: 'statusCode',
          type: FILTER_TYPES.MULTISELECT,
          value: [
            INVOICE_STATUS.STATUS_ADDED,
            INVOICE_STATUS.STATUS_SENT,
            INVOICE_STATUS.STATUS_REVIEWING,
            INVOICE_STATUS.STATUS_ACCEPTED,
            INVOICE_STATUS.STATUS_REJECTED,
          ],
        },
      ],
      sort: {
        direction: SORT_ORDER.ASC,
        column: 'created',
      },
    };

    return this.api.post(`/v1/CA8QL9/manage_invoices`, request);
  };

  getAccountantBusinessTrips = async (): Promise<IAccountantBusinessTripsResponse> => {
    return this.api.get(
      `/business-trips-api/business-trips?type=upcoming&businessTripReportStatus=${BusinessTripReportStatusEnum.InAccounting}`
    );
  };
}
