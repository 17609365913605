import { AuthenticationResult, Configuration, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import maxBy from 'lodash-es/maxBy';

import { getOrigin } from '../utils/urlsUtil';

export const AUTH_CONFIG = {
  tenantId: process.env.REACT_APP_AZURE_AUTH_TENANT_ID,
  clientId: process.env.REACT_APP_AZURE_AUTH_CLIENT_ID,
  redirectUri: `${window.location.origin}${process.env.PUBLIC_URL}/auth`,
  logoutUrl:
    process.env.NODE_ENV === 'development'
      ? `${window.location.origin}${process.env.PUBLIC_URL}`
      : `${getOrigin() + '/logout'}`, // sign out from SSO and then redirect to old portal logout, so we can sign out there as well
  authScopes: [`${process.env.REACT_APP_AZURE_AUTH_CLIENT_ID}/.default`],
  resourceScopes: {
    powerbi: ['https://analysis.windows.net/powerbi/api/.default'],
  },
};

export const authConfig: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${AUTH_CONFIG.tenantId}`,
    clientId: `${AUTH_CONFIG.clientId}`,
    redirectUri: AUTH_CONFIG.redirectUri,
    postLogoutRedirectUri: AUTH_CONFIG.logoutUrl,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    iframeHashTimeout: 3000,
  },
};

export const authParameters: RedirectRequest = {
  scopes: AUTH_CONFIG.authScopes,
};

export const msalInstance = new PublicClientApplication(authConfig);

export const acquireAccessToken = async (
  options: {
    scopes?: string[];
  } = {}
): Promise<AuthenticationResult | undefined | false> => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (window.Cypress) {
    return { accessToken: 'test-token' } as AuthenticationResult;
  }

  if (!activeAccount && accounts.length === 0) {
    return false;
  }

  const request = {
    ...authParameters,
    ...options,
    account: activeAccount || maxBy(accounts, 'idTokenClaims.exp'), // use the account that has the token with latest expiry date (should reflect the latest signed in user)
  };

  try {
    // initially try to fetch the token silently
    return await msalInstance.acquireTokenSilent(request);
  } catch (e) {
    try {
      // if silent token fetching fails then do it through redirect
      await msalInstance.acquireTokenRedirect(request);
    } catch (e) {
      console.error(e);
    }
  }
};
