import React from 'react';
import { Navigate } from 'react-router-dom';

import { flagComparison } from '../../../components/feature-flag/FeatureFlagGuard';
import { useWhoAmIDto } from '../../auth/Auth.hooks';
import { ROLES } from '../../auth/AuthTypes';
import { useGetFeatureFlagsQuery } from '../../store/GeneralApiStore';
import { paths } from '../routeMap';
import { ICustomRouteProps } from './CustomRoute';

interface IRouteGuardProps {
  route: ICustomRouteProps;
}

/**
 * Route wrapper component that determines if correct conditions are met to display the route
 */
const RouteGuard = ({ route }: IRouteGuardProps) => {
  const { whoAmIDto, isWhoAmISuccess, myRoles } = useWhoAmIDto();
  const { data: featureFlagsDto, isLoading: isFeatureFlagsDtoLoading } = useGetFeatureFlagsQuery();

  if (!isWhoAmISuccess || isFeatureFlagsDtoLoading) {
    // still fetching guard info
    return null;
  } else if (route?.contractLock && whoAmIDto?.contracts?.length === 0) {
    return <Navigate to={paths.unauthorized} replace />;
  } else if (route?.roleLock?.roles && !myRoles?.some((r: ROLES) => route?.roleLock?.roles.includes(r))) {
    // if user doesn't have necessary roles then redirect to fallback
    return <Navigate to={route?.roleLock?.fallbackRoute || '/'} replace />;
  } else if (
    route?.featureFlagLock?.flag &&
    !flagComparison(route?.featureFlagLock?.flag, featureFlagsDto, whoAmIDto)
  ) {
    // if route is locked behind a specific feature flag
    return <Navigate to={route?.featureFlagLock?.fallbackRoute || '/'} replace />;
  } else if (route.component) {
    return route.component;
  } else {
    return null;
  }
};

export default RouteGuard;
