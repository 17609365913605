import React from 'react';
import { Alert, Linkify, STATUS_TYPE } from 'taltech-styleguide';

import { useGetNoticesQuery } from '../../common/store/GeneralApiStore';
import { NOTICE_TYPE_MAP } from '../../common/store/GeneralTypes';

// TODO support multiple notices from BE
const Notices: React.FC = () => {
  const interval = process.env.NODE_ENV === 'development' ? 120000 : 30000; // make interval larger for dev so it doesn't clutter the network tab
  const { data } = useGetNoticesQuery(undefined, { pollingInterval: interval });

  return data?.text ? (
    <div>
      <Alert variant={NOTICE_TYPE_MAP[data.status] || STATUS_TYPE.INFO}>
        <Linkify>{data.text}</Linkify>
      </Alert>
    </div>
  ) : null;
};

export default React.memo(Notices);
