import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import minMax from 'dayjs/plugin/minMax';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { LANGUAGES, LOCALE_NS } from '../constants/locale';
import { setDOMLang } from '../utils/langUtil';

import 'dayjs/locale/et';
import 'dayjs/locale/en';

dayjs.extend(customParseFormat);
dayjs.extend(minMax);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('lang') || LANGUAGES.ET,
    fallbackLng: LANGUAGES.ET,
    ns: Object.values(LOCALE_NS),
    defaultNS: LOCALE_NS.DEFAULT,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i', 'p', 'ul', 'ol', 'li'],
      useSuspense: !(process.env.NODE_ENV === 'test'), // if we are testing we don't want to wait for the translations to load
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      ...(process.env.REACT_APP_CI_JOB_TOKEN ? { queryStringParams: { v: process.env.REACT_APP_CI_JOB_TOKEN } } : {}),
    },
  });

i18n.on('languageChanged', (lang: string) => {
  setDOMLang(lang, i18n.t('title'));
  localStorage.setItem('lang', lang);
  dayjs.locale(lang);
});

// change dayjs locale
dayjs.locale(localStorage.getItem('lang') || LANGUAGES.ET);

export default i18n;
