import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ICONS, Placeholder, Row } from 'taltech-styleguide';

import { withErrorBoundary } from '../../components/error-boundary/withErrorBoundary';

import './UnauthorizedView.scss';

type UnauthorizedViewProps = {
  text?: string | JSX.Element;
};

const UnauthorizedView: React.FC<UnauthorizedViewProps> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <Row className="container--dashboard mx-0" data-testid="unauthorized.view.content">
      <Col sm={12}>
        <Placeholder
          size="xl"
          icon={ICONS.LOCK}
          text={text || t('general:unauthorized')}
          className="tt-unauthorized-view_placeholder"
        />
      </Col>
    </Row>
  );
};

export default withErrorBoundary(UnauthorizedView);
