import { MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Loader } from 'taltech-styleguide';

import { logOut } from './common/auth/AuthStore';
import { msalInstance } from './common/auth/azureAuth';
import AuthenticationWrapper from './common/auth/components/AuthenticationWrapper';
import Routes from './common/router/Routes';
import store from './common/store/storeConfig';
import { ConfirmationModalProvider } from './components/confirmation-modal/ConfirmationProvider';

import './common/translation/i18n'; // initialize translations
import 'taltech-styleguide/index.css';
import './scss/styles.scss';

// detect accessibility issues in browser console
// if (process.env.NODE_ENV === 'development') {
//     import('react-axe').then((axe) => axe.default(React, ReactDOM, 1000));
// }

// show current version in console
console.log(`%cApp version: ${process.env.REACT_APP_VERSION}`, 'background: #222; color: #bada55;');

// only run sentry for production environments
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.REACT_APP_CI_ENVIRONMENT_SLUG,
    release: process.env.REACT_APP_VERSION || 'unknown',
    ignoreErrors: [
      'redirect_in_iframe',
      'interaction_in_progress',
      'Request aborted',
      'Network Error',
      'timeout of 60000ms exceeded',
    ],
  });
  Sentry.configureScope((scope: Sentry.Scope) => {
    scope.setTag('app_version', process.env.REACT_APP_VERSION || 'unknown');
  });
}

// if url has a logout part in it, then logout instantly before loading msal protected app
// useful for cases where external Taltech app is trying to log out, but portal 2.0 hasn't done a SSO login yet. This way it bypasses login in with SSO and then immediately logging out
// this should very rarely be used (only for old portal)
if (window.location.pathname.includes('/logout')) {
  // change url to dashboard before logging out. This id due to old portal taking it's post logout url from reference url
  window.history.replaceState({}, 'TalTech', process.env.PUBLIC_URL);
  logOut();
} else {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <React.Suspense fallback={<Loader fullScreen />}>
      <MsalProvider instance={msalInstance}>
        <AuthenticationWrapper>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Provider store={store}>
              <ConfirmationModalProvider>
                <Routes />
              </ConfirmationModalProvider>
            </Provider>
          </BrowserRouter>
        </AuthenticationWrapper>
      </MsalProvider>
    </React.Suspense>
  );
}
