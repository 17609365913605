import cn from 'classnames';
import qs from 'qs';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Alert, Container, ErrorBoundary, Loader, STATUS_TYPE, ToastContainer } from 'taltech-styleguide';

import { handleBoundaryCallback, withErrorBoundary } from '../../components/error-boundary/withErrorBoundary';
import TalTechHeader from '../../components/header/Header';
import Notices from '../../components/notices/Notices';
import { useLazyGetLeaveScheduleModeQuery } from '../../views/selfservice/leave/leaveApiStore';
import UnauthorizedView from '../../views/unauthorized/UnauthorizedView';
import { useWhoAmIDto } from '../auth/Auth.hooks';
import { useSetLangMutation } from '../auth/AuthApiStore';
import { initAuth } from '../auth/AuthStore';
import { generalApi } from '../store/GeneralApiStore';
import { useAppDispatch } from '../store/store.hooks';
import { isApp } from '../utils/environmentUtil';
import { setDOMLang } from '../utils/langUtil';
import renderCustomRoute from './components/CustomRoute';
import ExternalRedirect from './components/ExternalRedirect';
import { SELFSERVICE_ROOT } from './routeMap';
import { routesList } from './routePaths';

const AppRoutes: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { search, pathname } = useLocation();
  const { isWhoAmILoading, isWhoAmISuccess } = useWhoAmIDto();
  const [, { isLoading: isSwitchingLanguage }] = useSetLangMutation({
    fixedCacheKey: 'track-language-change',
  });
  const { isLoading: isFeatureFlagsLoading } = generalApi.endpoints.getFeatureFlags.useQueryState();

  const showLoader = useMemo(() => isWhoAmILoading || isFeatureFlagsLoading, [isWhoAmILoading, isFeatureFlagsLoading]);

  const queryParams = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
  /**
   * Show page content in fullscreen (covers header and footer as well)
   */
  const isFullscreen = useMemo(() => !!queryParams?.fullScreen, [queryParams?.fullScreen]);

  const [getLeaveScheduleMode] = useLazyGetLeaveScheduleModeQuery();

  useEffect(() => {
    setDOMLang(i18n.language, t('general:title'));
  }, [i18n.language, t]);

  useEffect(() => {
    dispatch(initAuth());
  }, [dispatch]);

  // when user navigates
  useEffect(() => {
    if (window.isMatomoActive && window.isMatomoActive()) {
      window._paq.push(['setCustomUrl', pathname]);
      window._paq.push(['trackPageView']);
    }

    // when navigating inside self-service, then fetch the schedule mode on every route change
    if (pathname.includes(SELFSERVICE_ROOT)) {
      getLeaveScheduleMode();
    }
  }, [dispatch, getLeaveScheduleMode, pathname]);

  const renderSwitch = useMemo(
    () => (
      <Routes>
        {isWhoAmISuccess ? (
          routesList.map((route, index) => renderCustomRoute({ ...route, index }))
        ) : (
          <Route path="*" element={<UnauthorizedView />} />
        )}
        <Route path="/auth" />
        <Route path="*" element={<ExternalRedirect />} />
      </Routes>
    ),
    [isWhoAmISuccess]
  );

  return (
    <div>
      {!isApp && !isFullscreen ? <TalTechHeader /> : null}
      <main className={cn({ 'mt-3': isApp })} data-testid="main.content" id="main-content-id">
        <Container body={true}>
          <ToastContainer />
          {/* TODO Wrap with <FeatureFlagGuard> when we have separate flags for pre-live and live */}
          {!isFullscreen && window.location.host === 'portal-prelive.taltech.ee' && (
            <Alert variant={STATUS_TYPE.INFO}>{t('general:prelive-live-data')}</Alert>
          )}
          {!isFullscreen && <Notices />}
          {showLoader ? (
            <Loader fullScreen />
          ) : (
            <div className={cn({ 'page--fullscreen': isFullscreen })}>
              <React.Suspense fallback={<Loader fullScreen />}>
                {isSwitchingLanguage && <Loader fullScreen />}
                <ErrorBoundary
                  placeholderOptions={{ padding: 'lg' }}
                  text={t('general:errors.fatal')}
                  onErrorCb={handleBoundaryCallback}
                >
                  {renderSwitch}
                </ErrorBoundary>
              </React.Suspense>
            </div>
          )}
        </Container>
      </main>
    </div>
  );
};

export default withErrorBoundary(AppRoutes);
