import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';

import { ILoadableDataAction, LoadableData } from './LoadableData';

// Pick only keys which values are LoadableData
type PickLoadableData<S> = Pick<
  S,
  {
    [Key in keyof S]: S[Key] extends LoadableData<any, any> ? Key : never;
  }[keyof S]
>;

/**
 * Little helper class that wraps ReducerFactory with custom function that adds 3 reducers(request, success and error) for LoadableDataAction
 * Helps to reduce boilerplate in reducer declaration files
 */
export class CustomReducerFactory<ST, Payload = never> extends ReducerFactory<ST, Payload> {
  public constructor(state: ST) {
    super(state);
  }

  public addLoadableReducer = (
    action: ILoadableDataAction<any, any, any>,
    data: keyof PickLoadableData<ST>,
    overrideLoadingMethod?: (loadable: LoadableData, action: any) => LoadableData
  ): CustomReducerFactory<ST, Payload> => {
    return this.addReducer(action.request, (state: ST, eventAction: Action<any>): ST => {
      const payload = eventAction ? eventAction.payload : undefined;
      let newData;

      if (overrideLoadingMethod) {
        // @ts-ignore
        newData = overrideLoadingMethod(this.state[data], payload);
      } else {
        newData = (state[data] as any).withLoading(payload);
      }

      return {
        ...state,
        [data]: newData,
      };
    })
      .addReducer(
        action.success,
        (state: ST, eventAction: Action<any>): ST => ({
          ...state,
          [data]: LoadableData.payload(eventAction.payload, (state[data] as any).request),
        })
      )
      .addReducer(
        action.error,
        (state: ST, eventAction: Action<any>): ST => ({
          ...state,
          [data]: LoadableData.error(eventAction.payload, (state[data] as any).request),
        })
      ) as CustomReducerFactory<ST, Payload>;
  };
}
