export enum SENTRY_API_ERRORS {
  FAULTY_RESPONSE = 'faulty_response',
  REQUEST_FAILED = 'request_failed',
  NOT_JSON = 'not_json',
}

export enum SENTRY_TAGS {
  APP_VERSION = 'app_version',
  API_ERROR = 'api_error',
  MIGRATION = 'migration',
  AUTH = 'auth_error',
}
