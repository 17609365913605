import * as Sentry from '@sentry/browser';
import i18next from 'i18next';
import React, { ErrorInfo } from 'react';
import { ErrorBoundary } from 'taltech-styleguide';

/**
 * HOC to wrap different components in Error Boundary
 * If Sentry is active, then sends the error to Sentry
 */
export function withErrorBoundary<T>(Component: React.ComponentType<T>) {
  const ComponentRender: any = Component;

  return (props: T) => (
    <ErrorBoundary
      placeholderOptions={{ padding: 'md' }}
      text={i18next.t('general:errors.fatal')}
      onErrorCb={handleBoundaryCallback}
    >
      <ComponentRender {...props} />
    </ErrorBoundary>
  );
}

export const handleBoundaryCallback = async (e: Error, eInfo: ErrorInfo) => {
  Sentry.captureException(e);
};
