export enum LOCALE_NS {
  DEFAULT = 'general',
  BREADCRUMBS = 'breadcrumbs',
  DASHBOARD = 'dashboard',
  SEARCH = 'search',
  CONTACT = 'contact',
  SELFSERVICE = 'selfservice',
  POWERBI = 'powerbi',
  INTRA_TV = 'intra-tv',
  LEAVE = 'leave',
  SECONDMENTS = 'secondments',
  PROFILE = 'profile',
  ROOMS = 'rooms',
  EXPENSES = 'expenses',
  TRAININGS = 'trainings',
  ROLES_HUB = 'roles-hub',
}

export enum LANGUAGES {
  ET = 'et',
  EN = 'en',
}
