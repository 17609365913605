import { Action, ActionFunction1, createAction } from 'redux-actions';

/**
 * @type D - loadable Data payload type
 * @type R - Request type of loadable data
 */
export interface IData<D, R> {
  request: R | undefined;
  payload: D | undefined;
}

interface ILoadableData<D, R> extends IData<D, R> {
  loading: boolean | undefined;
  loaded: boolean | undefined;
  error: Error | undefined | unknown;
}

export interface IPersistableData<D, R> extends ILoadableData<D, R> {
  persistUntil: NonNullable<number>;
}

export class LoadableData<D = any, R = void> implements ILoadableData<D, R> {
  request: R | undefined;
  payload: D | undefined;
  loading: boolean | undefined;
  loaded: boolean | undefined;
  error: Error | undefined | unknown;

  constructor(initialPayload?: D) {
    this.payload = initialPayload;
  }

  /* istanbul ignore next */
  withPersist(persistUntil: NonNullable<number>): IPersistableData<D, R> {
    return {
      request: this.request,
      payload: this.payload,
      loading: this.loading,
      loaded: this.loaded,
      error: this.error,
      persistUntil: Date.now() + persistUntil,
    };
  }

  withReset(payload?: D) {
    return LoadableData.create<D, R>({
      request: undefined,
      loading: false,
      loaded: false,
      payload,
      error: undefined,
    });
  }

  withLoadingReset(request?: R) {
    return LoadableData.create<D, R>({
      request,
      loading: true,
      loaded: false,
      payload: undefined,
      error: undefined,
    });
  }

  withLoading(request?: R) {
    return LoadableData.create<D, R>({
      request,
      loading: true,
      loaded: this.loaded,
      payload: this.payload,
      error: undefined,
    });
  }

  withError(error?: Error | undefined) {
    return LoadableData.create<D, R>({
      request: this.request,
      loading: false,
      loaded: true,
      payload: this.payload,
      error,
    });
  }

  /* istanbul ignore next */
  withPayloadIfRequestEquals(requestAndResult: ILoadedResult<R, D>) {
    const { request, result } = requestAndResult;
    if (request !== this.request) {
      return this;
    }
    return LoadableData.payload<D, R>(result);
  }

  /* istanbul ignore next */
  withErrorIfRequestEquals(requestAndResult: ILoadedResult<R, Error>) {
    const { request, result } = requestAndResult;
    if (request !== this.request) {
      return this;
    }
    return LoadableData.error<D, R>(result);
  }

  static loading<D, R = void>(request?: R) {
    return LoadableData.create<D, R>({
      request,
      loading: true,
      loaded: undefined,
      payload: undefined,
      error: undefined,
    });
  }

  static payload<D, R = void>(payload: D, request?: R) {
    return LoadableData.create<D, R>({
      request,
      payload,
      loading: false,
      loaded: true,
      error: undefined,
    });
  }

  static error<D, R = void>(error: Error | undefined | unknown, request?: R) {
    return LoadableData.create<D, R>({
      request,
      error,
      payload: undefined,
      loading: false,
      loaded: true,
    });
  }

  private static create<D, R>(values: ILoadableData<D, R>) {
    const result = new LoadableData<D, R>();
    result.request = values.request;
    result.payload = values.payload;
    result.loading = values.loading;
    result.loaded = values.loaded;
    result.error = values.error;
    return result;
  }
}

/**
 * Helper function for creating redux actions that can be dispatched when starting or completed loading
 * @type <I> - Input(request) type used for loading
 * @type <O> - Output(response) type in case of successful loading
 */
export function loadableDataActions<I, O, E = Error | unknown>(actionTypePrefix: string): ILoadableDataAction<I, O, E> {
  const request = createAction<I>(actionTypePrefix + '_REQUEST');
  const success = createAction<O>(actionTypePrefix + '_SUCCESS');
  const error = createAction<E>(actionTypePrefix + '_ERROR');
  return {
    request,
    success,
    error,
  };
}

export interface ILoadableDataAction<I = any, O = any, E = any> {
  request: ActionFunction1<I, Action<I>>;
  success: ActionFunction1<O, Action<O>>;
  error: ActionFunction1<E, Action<E>>;
}

export function loadableDataActionsWithRequest<I, O>(actionTypePrefix: string) {
  return loadableDataActions<I, ILoadedResult<I, O>, ILoadedResult<I, Error>>(actionTypePrefix);
}

export interface ILoadedResult<I, O> {
  request: I;
  result: O;
}
