import { STATUS_TYPE } from 'taltech-styleguide';

import { IOldTableRequest, IOldTableResponse, ISO8601 } from '../../../common/api/GlobalTypes';
import { LANGUAGES } from '../../../common/constants/locale';

export interface ISecondment {
  approved: ISO8601;
  city: string;
  country: string;
  created: ISO8601;
  creator_id: string;
  creator_name: string;
  date_from: ISO8601;
  date_to: ISO8601;
  goal: string;
  goal_other: string;
  has_report: boolean;
  id: string;
  mission_type: string;
  org: string;
  person_id: string;
  person_name: string;
  rejected: ISO8601;
  status: SECONDMENT_STATUS;
  uri: string;
}

export interface IExpense {
  approved: ISO8601;
  created: ISO8601;
  creator_id: string;
  creator_name: string;
  id: string;
  new_checks: number;
  person_id: string;
  person_name: string;
  rejected: ISO8601;
  status: EXPENSES_STATUS;
  sum: number;
  title: string;
  uri: string;
}

export interface IService {
  id: string;
  subject: string;
  details: string;
  location: string;
  registered: ISO8601;
  changed: ISO8601;
  status: SERVICES_STATUS;
  uri: string;
}

export interface IAppraisal {
  id: string;
  head: string;
  manager: string;
  employee: string;
  created: ISO8601;
  appointment: ISO8601;
  invitation: ISO8601;
  closed: string;
  status: APPRAISALS_STATUS;
  uri: string;
}

export interface IInvoice {
  id: string;
  created: ISO8601;
  approved: ISO8601;
  issuer_name: string;
  issuer_ou: number;
  rejected: ISO8601;
  sa_nr: string;
  sm_nr: string;
  status: INVOICE_STATUS;
  status_name: string;
  sum: number;
  target_name: string;
  title: string;
  uri: string;
}
export interface IBusinessTripListItem {
  id: number;
  businessTripOrderId: number;
  businessTripOrderIdentifier: string;
  businessTripReportId: number;
  businessTripReportIdentifier: string;
  employeeName: string;
  period: {
    startDate: string;
    endDate: string;
  };
  origin: {
    country: string; // ISO code
    city: string; // Free text
  };
  destination: {
    country: string; // ISO code
    city: string; // Free text
  };
  organisationName: string; // Free text
  financeSourceCodes: string[];
  projectCodes: string[];
  businessTripOrderStatus: BusinessTripOrderStatusEnum;
  businessTripReportStatus: BusinessTripReportStatusEnum;
  hasAdvancePayment: boolean;
}

export enum BusinessTripOrderStatusEnum {
  InProgress = 'in_progress',
  InApproval = 'in_approval',
  Approved = 'approved',
  Rejected = 'rejected',
  Canceled = 'canceled',
}
export enum BusinessTripReportStatusEnum {
  InProgress = 'in_progress',
  InApproval = 'in_approval',
  Approved = 'approved',
  InAccounting = 'in_accounting',
  AwaitingInfo = 'awaiting_info',
  Accounted = 'accounted',
  Rejected = 'rejected',
}

export const BUSINESS_TRIP_ORDER_STATUS_MAP = {
  [BusinessTripOrderStatusEnum.InProgress]: STATUS_TYPE.INFO,
  [BusinessTripOrderStatusEnum.InApproval]: STATUS_TYPE.INFO,
  [BusinessTripOrderStatusEnum.Approved]: STATUS_TYPE.SUCCESS,
  [BusinessTripOrderStatusEnum.Rejected]: STATUS_TYPE.DANGER,
  [BusinessTripOrderStatusEnum.Canceled]: STATUS_TYPE.LIGHT,
};

export const BUSINESS_TRIP_REPORT_STATUS_MAP = {
  [BusinessTripReportStatusEnum.InProgress]: STATUS_TYPE.INFO,
  [BusinessTripReportStatusEnum.InApproval]: STATUS_TYPE.INFO,
  [BusinessTripReportStatusEnum.Approved]: STATUS_TYPE.SUCCESS,
  [BusinessTripReportStatusEnum.InAccounting]: STATUS_TYPE.INFO,
  [BusinessTripReportStatusEnum.AwaitingInfo]: STATUS_TYPE.WARNING,
  [BusinessTripReportStatusEnum.Accounted]: STATUS_TYPE.SUCCESS,
  [BusinessTripReportStatusEnum.Rejected]: STATUS_TYPE.DANGER,
};

export enum SELF_SERVICE_REQUEST_STATUS {
  OK = 0,
  FAIL = 1,
}

export enum SECONDMENT_STATUS {
  STATUS_ADDED = 0,
  STATUS_REVIEWING = 1,
  STATUS_REJECTED = 2,
  STATUS_ACCEPTED = 3,
  STATUS_CANCELLED = 4,
}

export enum MANAGER_SECONDMENT_STATUS {
  STATUS_APPROVING = 0,
  STATUS_APPROVED = 1,
}

export const SELFSERVICE_STATUS_MAP = {
  0: STATUS_TYPE.LIGHT,
  1: STATUS_TYPE.INFO,
  2: STATUS_TYPE.DANGER,
  3: STATUS_TYPE.SUCCESS,
  4: STATUS_TYPE.LIGHT,
  5: STATUS_TYPE.LIGHT,
};

export enum EXPENSES_STATUS {
  STATUS_ADDED = 0,
  STATUS_REVIEWING = 1,
  STATUS_REJECTED = 2,
  STATUS_ACCEPTED = 3,
  STATUS_CANCELLED = 4,
  STATUS_DELETED = 5,
}

export enum MANAGER_EXPENSES_STATUS {
  STATUS_APPROVING = 0,
  STATUS_APPROVED = 1,
}

export enum SERVICES_STATUS {
  STATUS_INCOMPLETE = 0,
  STATUS_COMMITED = 1,
  STATUS_SOLVING = 2,
  STATUS_CLOSED = 3,
  STATUS_CANCELLED = 4,
  STATUS_WAITING = 5,
}

export const SERVICE_STATUS_MAP = {
  0: STATUS_TYPE.LIGHT,
  1: STATUS_TYPE.INFO,
  2: STATUS_TYPE.INFO,
  3: STATUS_TYPE.SUCCESS,
  4: STATUS_TYPE.LIGHT,
  5: STATUS_TYPE.INFO,
};

export enum APPRAISALS_STATUS {
  STATUS_STARTED = 0,
  STATUS_SENT = 1,
  STATUS_EMPLOYEE = 2,
  STATUS_COMPLETED = 3,
}

export enum INVOICE_STATUS {
  STATUS_ADDED = 0,
  STATUS_SENT = 1,
  STATUS_REVIEWING = 2,
  STATUS_ACCEPTED = 3,
  STATUS_REJECTED = 4,
  STATUS_CANCELLED = 5,
  STATUS_DELETED = 6,
}

export enum MANAGER_APPRAISALS_STATUS {
  STATUS_APPROVING = 0,
  STATUS_INVITATION_NOT_SENT = 1,
}

export const APPRAISALS_STATUS_MAP = {
  0: STATUS_TYPE.LIGHT,
  1: STATUS_TYPE.INFO,
  2: STATUS_TYPE.LIGHT,
  3: STATUS_TYPE.SUCCESS,
};
export type IMySecondmentsRequest = IOldTableRequest<SECONDMENT_STATUS[]> & {
  employee_id?: string;
  lang: {
    value: LANGUAGES;
  };
};
export type IMySecondmentsResponse = IOldTableResponse & {
  records: ISecondment[];
};
export type IMyBusinessTripsRequest = {
  employee_uni_id: string;
};
export type IMyBusinessTripsResponse = IBusinessTripListItem[];
export type IMyExpensesRequest = IOldTableRequest<EXPENSES_STATUS[]>;
export type IMyExpensesResponse = IOldTableResponse & {
  records: IExpense[];
};
export type IMyServicesRequest = IOldTableRequest<SERVICES_STATUS[]>;
export type IMyServicesResponse = IOldTableResponse & {
  records: IService[];
};
export type IMyAppraisalsRequest = IOldTableRequest<APPRAISALS_STATUS[]>;
export type IMyAppraisalsResponse = IOldTableResponse & {
  records: IAppraisal[];
};
export type IMyInvoicesRequest = IOldTableRequest<INVOICE_STATUS[]>;
export type IMyInvoicesResponse = IOldTableResponse & {
  records: IInvoice[];
};
export type IManagerSecondmentsRequest = IOldTableRequest<MANAGER_SECONDMENT_STATUS[]> & {
  lang: {
    value: LANGUAGES;
  };
};
export type IManagerSecondmentsResponse = IOldTableResponse & {
  records: ISecondment[];
};
export type IManagerBusinessTripsResponse = IBusinessTripListItem[];
export type IManagerExpensesRequest = IOldTableRequest<MANAGER_EXPENSES_STATUS[]>;
export type IManagerExpensesResponse = IOldTableResponse & {
  records: IExpense[];
};
export type IManagerAppraisalsRequest = IOldTableRequest<MANAGER_APPRAISALS_STATUS[]>;
export type IManagerAppraisalsResponse = IOldTableResponse & {
  records: IAppraisal[];
};
export type IManagerInvoicesRequest = IOldTableRequest<INVOICE_STATUS[]>;
export type IManagerInvoicesResponse = IOldTableResponse & {
  records: IInvoice[];
};
export type IAccountantBusinessTripsResponse = IBusinessTripListItem[];
