// Feature flags that enable parts of the app
// Must match the ones coming from the API
export enum FEATURE_FLAGS {
  SEARCH_FLOW = 'SEARCH_FLOW',
  INTRA_TV = 'INTRA_TV',
  FORCE_LEAVE_SCHEDULE = 'FORCE_LEAVE_SCHEDULE',
  ALLOW_USER_SWITCH = 'ALLOW_USER_SWITCH',
  SHOW_GANT_GRAPH = 'SHOW_GANT_GRAPH',
  SHOW_LEAVE_SCHEDULE_FOR_HR = 'SHOW_LEAVE_SCHEDULE_FOR_HR',
  SHOW_HR_TO_MANAGER_SCHEDULE_LINK = 'SHOW_HR_TO_MANAGER_SCHEDULE_LINK',
  ALLOW_POWERBI_MANAGE = 'ALLOW_POWERBI_MANAGE',
  NEW_EXPENSES_FLOW = 'NEW_EXPENSES_FLOW',
  HIGHLIGHT_MISSING_EXPENSE_HISTORY_TRANSLATIONS = 'HIGHLIGHT_MISSING_EXPENSE_HISTORY_TRANSLATIONS',
  DISABLE_OLD_EXPENSES_FLOW = 'DISABLE_OLD_EXPENSES_FLOW',
  NEW_INTERNAL_TRAININGS_FLOW = 'NEW_INTERNAL_TRAININGS_FLOW',
  NEW_LEAVE_APPLICATION_FLOW = 'NEW_LEAVE_APPLICATION_FLOW',
  NEW_ROLES_HUB = 'NEW_ROLES_HUB',
  NEW_BUSINESS_TRIPS_FLOW = 'NEW_BUSINESS_TRIPS_FLOW',
}
