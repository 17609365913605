import { generatePath } from 'react-router-dom';
import { ILevel1Link, isExternalUrl } from 'taltech-styleguide';

import { IAnchorProps, LinkBehaviour } from '../../components/anchor/Anchor';
import { IMenuResponse } from '../store/GeneralApiStore';
import { IFooterLinkDto } from '../store/GeneralTypes';
import { getEnvironmentSpecificValue } from './environmentUtil';

export const getOrigin = () => {
  return getEnvironmentSpecificValue({
    production: window.location.origin,
    test: window.location.origin,
    default: process.env.REACT_APP_FALLBACK_URL,
  });
};

export const formPost = (path: string, params: { [key: string]: any } = {}, method: string = 'post') => {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  Object.keys(params).forEach((key: string) => {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', key);
    hiddenField.setAttribute('value', params[key]);
    form.appendChild(hiddenField);
  });

  document.body.appendChild(form);
  form.submit();
  form.remove();
};

/**
 * Parse menu API urls.
 * Since urls in the menu API response can be relative(e.g. /ths/y, /v2/x or /search)
 * we need to add origin in front of urls that are not local portal urls(links that don't have http(s), www. or /v2 in the beginning)
 * Also add active attribute to Portal level1 link.
 */
export const parseMenu = (menu: IMenuResponse): { slogans: string[]; links: ILevel1Link<typeof LinkBehaviour>[] } => {
  return { slogans: menu.slogans, links: menu?.links?.length ? reduceMenu(menu?.links, true) : [] };
};

const reduceMenu = (links: any[], isLevel1?: boolean) => {
  const basePath = process.env.PUBLIC_URL;

  return links.reduce((accumulator: any[], link: any) => {
    const newLink: any = {};

    // set active Level1Link
    if (isLevel1 && link.to?.indexOf(basePath) === 0) {
      newLink.active = true;
    } else if (link.active !== undefined) {
      newLink.active = link.active;
    }

    // parse label
    if (link.label) {
      newLink.children = link.label;
    }

    // parse url
    if (link.to) {
      newLink.to = parseReactUrl(link.to);
    }

    if (link.openInTab) {
      newLink.target = '_blank';
    }

    // recursively go parse the children links as well
    if (link.children?.length) {
      newLink.items = reduceMenu(link.children);
    }

    // recursively go parse the top links as well
    if (link.topLinks?.length) {
      newLink.topLinks = reduceMenu(link.topLinks);
    }

    return [...accumulator, newLink];
  }, []);
};

const parseReactUrl = (url: string): string => {
  const basePath = process.env.PUBLIC_URL;

  if (isExternalUrl(url)) {
    // url starts with http(s) or www. so url is already absolute
    return url;
  } else {
    // remove "/v2" from beginning of urls for the react router to work. Since we set basepath as "/v2" in createBrowserHistory, urls don't need to have it twice
    return url.replace(basePath, '');
  }
};

/**
 * Parse footer links
 */
export const parseFooterUrls = (links: IFooterLinkDto[]): IAnchorProps[] => {
  return links?.map((l) => ({
    children: l.title,
    to: l.href,
    target: l.target,
  }));
};

/**
 * Wrap React routers generatePath function, so we have easier typing constraints
 */
export const generateUrl = <Path extends string>(path: Path, params: { [key: string]: string | undefined } = {}) =>
  generatePath(path, params as any);
