import * as Sentry from '@sentry/browser';
import { ILevel1Link } from 'taltech-styleguide';

import { LinkBehaviour } from '../../components/anchor/Anchor';
import { apiBase } from '../api/Api.utils';
import { HTML } from '../api/GlobalTypes';
import { SENTRY_API_ERRORS, SENTRY_TAGS } from '../constants/sentry';
import i18nInstance from '../translation/i18n';
import { getOrigin, parseMenu } from '../utils/urlsUtil';
import { IFeatureFlags, IMenuLinks, NOTICE_TYPE_MAP } from './GeneralTypes';

export interface INoticesResponse {
  text: string;
  status: keyof typeof NOTICE_TYPE_MAP;
}

export interface IMenuResponse {
  links: IMenuLinks;
  slogans: string[];
}

export const generalApi = apiBase.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<IFeatureFlags, void>({
      query: () => `/api/get:feature-flags`,
      extraOptions: {
        anonymous: true,
        onError: (e) => {
          console.error('Loading feature flags failed', e);
          Sentry.withScope((scope) => {
            scope.setTag(SENTRY_TAGS.API_ERROR, SENTRY_API_ERRORS.REQUEST_FAILED);
            scope.setExtra('api_data', (e?.data as string)?.slice(0, 100));
            Sentry.captureException(e);
          });
        },
      },
    }),
    getAppVersion: builder.query<{ [key: string]: any }, void>({
      query: () => `${process.env.PUBLIC_URL}/manifest.json`,
      extraOptions: {
        anonymous: true,
      },
    }),
    getNotices: builder.query<INoticesResponse, void>({
      query: () => `/api/get:notification`,
    }),
    getMenu: builder.query<Pick<IMenuResponse, 'slogans'> & { links: ILevel1Link<typeof LinkBehaviour>[] }, void>({
      query: () => `/api/get:menu`,
      extraOptions: {
        onErrorString: () => i18nInstance.t('general:errors.load.navigation'),
      },
      transformResponse: (response: IMenuResponse) => parseMenu(response),
    }),
    getHelp: builder.query<HTML, string>({
      query: (focus) => ({
        url: `/api/get:help:${focus}`,
        responseHandler: 'text', // expect response as text/ expect response as text
      }),
      transformResponse: (response: HTML, meta, focus) =>
        response +
        `<a href="${getOrigin()}/wiki/show/${i18nInstance.language}:applications:portal:${focus}:help">Wiki</a>`,
    }),
    getFaq: builder.query<HTML, string>({
      query: (focus) => ({
        url: `/api/get:faq:${focus}`,
        responseHandler: 'text', // expect response as tex
      }),
      transformResponse: (response: HTML, meta, focus) =>
        response +
        `<a href="${getOrigin()}/wiki/show/${i18nInstance.language}:applications:portal:${focus}:faq">Wiki</a>`,
    }),
  }),
});

export const { useGetNoticesQuery, useGetMenuQuery, useGetFeatureFlagsQuery, useLazyGetHelpQuery, useLazyGetFaqQuery } =
  generalApi;
