import { LANGUAGES } from '../constants/locale';

// used to map roles specified in WhoAmI request as params into more readable enum
export enum ROLES {
  EMPLOYEE = 'EMPLOYEE',
  HR = 'HR', // human resources
  DIV_MANAGER = 'DIV_MANAGER', // 2. level manager
  MANAGER = 'MANAGER', // 3. level manager
  SUB_DIV_MANAGER = 'SUB_DIV_MANAGER', // 4. level manager
  MANAGER_ASSISTANT = 'MANAGER_ASSISTANT', // assistant to 3. level manager. Can access the same views as MANAGER and SUB_DIV_MANAGER, but those views are read-only (one exception is that they can still do actions as employee. For example submit leave applications as them)
  STUDENT = 'STUDENT',
  SUBSTITUTE = 'SUBSTITUTE',
  ACCOUNTANT = 'FINANCE', // accountant
  AUDITOR = 'AUDITOR', // accesses most views as ACCOUNTANT, but can only view
}

// Roles that are allowed to view manager views (This doesn't necessarily mean do manager actions)
export const ALL_MANAGER_ROLES = [ROLES.MANAGER, ROLES.SUB_DIV_MANAGER, ROLES.MANAGER_ASSISTANT];

export enum USER_PREFS {
  AVATAR = 'avatar',
  COLLAPSED = 'collapsed',
  FOOD_MENU_FAVOURITES = 'food_menu_favorites',
  BIRTHDAY = 'birthday',
  VEHICLES = 'vehicles',
  INTRODUCTION = 'introduction',
}

export enum USER_PREFS_ACTION_TYPES {
  ADD = 'add',
  DELETE = 'del',
  UPDATE = 'update',
}

export interface IWhoAmIDto {
  Sn: string;
  avatar: string;
  contracts: IContractDto[];
  displayname: string;
  email: string;
  employeeID: string;
  favourite_reports: string[];
  gender: string;
  givenName: string;
  isStudent: boolean;
  isWorker: boolean;
  isManagerAssistantOf: string[];
  isManagerOf: string[];
  isSubdivManagerOf: string[];
  lang: LANGUAGES;
  login_name: string;
  managerID: string;
  managerName: string;
  memberof: string[];
  roles: ROLES[];
  personcode: string;
  phone: string;
  title: string;
  workMobile: string;
  employees: any[]; // @TODO remove after replacing how employees are selected in LeaveApplicationChoiceView.tsx
  user_prefs: {
    [USER_PREFS.AVATAR]: { hide: Array<'www' | 'intra'> };
    [USER_PREFS.COLLAPSED]: string[];
    [USER_PREFS.FOOD_MENU_FAVOURITES]: string[];
    [USER_PREFS.BIRTHDAY]: { hide: Array<'intra'> };
    [USER_PREFS.VEHICLES]: { registrationPlate: string }[];
    [USER_PREFS.INTRODUCTION]: {
      showInPortal: boolean;
      showInWeb: boolean;
      etisLink: string;
      orcid: string;
      website: string;
      description: string;
    };
  };
}

export interface IContractDto {
  City: string;
  dvCode: string;
  dvName: string;
  dvNameEn: string;
  dvShortName: string;
  employeeID: string;
  employees: IPartialEmployeeProfile[];
  givenName: string;
  ID: string;
  isHR: boolean;
  isManager: boolean;
  isSubdivManager: boolean;
  isManagerAssistant: boolean;
  last_change: string;
  ManagerID: string | boolean;
  ManagerName: string | boolean;
  ouCode: string;
  ouName: string;
  ouNameEn: string;
  ouParent: string;
  ouShortName: string;
  personCode: string;
  PostalCode: string;
  roomNo: string;
  Sn: string;
  Speciality: string;
  Street: string;
  titleName: string;
  titleNameEn: string;
  UniID: string;
  workEmail: string;
  workMobile: string;
  workMobileShortNo: string;
  workPhoneNo: string;
}

export type IPartialEmployeeProfile = Pick<IContractDto, 'employeeID' | 'givenName' | 'Sn' | 'UniID'>;
