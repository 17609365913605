import { lazyComponent } from '../../components/lazy-component/lazyComponent';
import { ALL_MANAGER_ROLES, ROLES } from '../auth/AuthTypes';
import { FEATURE_FLAGS } from '../constants/featureFlags';
import { ICustomRouteProps } from './components/CustomRoute';
import {
  CONTACT_DETAILS,
  DASHBOARD_ROOT,
  DASHBOARD_TAB_NEWS,
  DASHBOARD_TAB_TRAININGS,
  EXPENSES_ROOT,
  INTRA_TV_ROOT,
  paths,
  POWERBI_MANAGE,
  POWERBI_MANAGE_CATEGORIES,
  POWERBI_MANAGE_REPORT,
  POWERBI_REPORT,
  POWERBI_ROOT,
  PROFILE,
  PROFILE_ROOT,
  ROOMS,
  ROOMS_ADD,
  ROOMS_DETAIL,
  ROOMS_DETAIL_EDIT,
  ROOMS_ROOT,
  SEARCH_ROOT,
  SELFSERVICE,
  SELFSERVICE_ANNUAL_LEAVE_APPLICATION,
  SELFSERVICE_ANNUAL_LEAVE_APPLICATION_ADD,
  SELFSERVICE_ANNUAL_LEAVE_APPLICATION_EDIT,
  SELFSERVICE_ANNUAL_LEAVE_APPLICATION_NEW,
  SELFSERVICE_CARETAKER_LEAVE_APPLICATION_ADD,
  SELFSERVICE_CARETAKER_LEAVE_APPLICATION_EDIT,
  SELFSERVICE_DEGREE_STUDY_LEAVE_APPLICATION_ADD,
  SELFSERVICE_DEGREE_STUDY_LEAVE_APPLICATION_EDIT,
  SELFSERVICE_EMPLOYEE_LEAVE_OVERVIEW,
  SELFSERVICE_FUNERAL_LEAVE_APPLICATION_ADD,
  SELFSERVICE_FUNERAL_LEAVE_APPLICATION_EDIT,
  SELFSERVICE_HEALTH_DAYS_LEAVE_APPLICATION_ADD,
  SELFSERVICE_HEALTH_DAYS_LEAVE_APPLICATION_EDIT,
  SELFSERVICE_HR_LEAVE_APPLICATION_CHOICE,
  SELFSERVICE_HR_LEAVE_MANAGEMENT,
  SELFSERVICE_HR_LEAVE_MANAGEMENT_ROOT,
  SELFSERVICE_LEAVE_APPLICATION_CHOICE,
  SELFSERVICE_LEAVE_DETAILS,
  SELFSERVICE_MANAGER_LEAVE_APPLICATION_CHOICE,
  SELFSERVICE_MANAGER_LEAVE_MANAGEMENT,
  SELFSERVICE_MANAGER_LEAVE_MANAGEMENT_ROOT,
  SELFSERVICE_MARRIAGE_LEAVE_APPLICATION_ADD,
  SELFSERVICE_MARRIAGE_LEAVE_APPLICATION_EDIT,
  SELFSERVICE_MY_LEAVE_OVERVIEW,
  SELFSERVICE_OTHER_LEAVE,
  SELFSERVICE_PARENTAL_LEAVE_APPLICATION_ADD,
  SELFSERVICE_PARENTAL_LEAVE_APPLICATION_EDIT,
  SELFSERVICE_ROLES_HUB_DELEGATE_ROLE_ADD,
  SELFSERVICE_ROLES_HUB_DELEGATE_ROLE_EDIT,
  SELFSERVICE_ROLES_HUB_EMPLOYEE_ROLES_OVERVIEW,
  SELFSERVICE_ROLES_HUB_MY_ROLES_OVERVIEW,
  SELFSERVICE_ROOT,
  SELFSERVICE_STUDY_LEAVE_APPLICATION_ADD,
  SELFSERVICE_STUDY_LEAVE_APPLICATION_EDIT,
  SELFSERVICE_UNPAID_LEAVE_APPLICATION_ADD,
  SELFSERVICE_UNPAID_LEAVE_APPLICATION_EDIT,
  SELFSERVICE_UNPAID_MATERNITY_LEAVE_APPLICATION_ADD,
  SELFSERVICE_UNPAID_MATERNITY_LEAVE_APPLICATION_EDIT,
  SELFSERVICE_UNPAID_STUDY_LEAVE_APPLICATION_ADD,
  SELFSERVICE_UNPAID_STUDY_LEAVE_APPLICATION_EDIT,
  TRAINING_ROOT,
  UNAUTHORIZED,
} from './routeMap';

// dynamic component loading (for code splitting)
const AsyncUnauthorizedView = lazyComponent(() => import('../../views/unauthorized/UnauthorizedView'));
const AsyncDashboardView = lazyComponent(() => import('../../views/dashboard/DashboardView'));
const AsyncContactView = lazyComponent(() => import('../../views/contact/ContactView'));
const AsyncIntraTVView = lazyComponent(() => import('../../views/intra-tv/IntraTvView'));
const AsyncSearchView = lazyComponent(() => import('../../views/search/SearchView'));
const AsyncSelfServiceViewWrapper = lazyComponent(() => import('../../views/selfservice/SelfServiceViewWrapper'));
const AsyncExpensesRoot = lazyComponent(() => import('../../views/selfservice/expenses/ExpensesRoot'));
const AsyncManagerLeaveView = lazyComponent(
  () => import('../../views/selfservice/leave/manager-leave/ManagerLeaveView')
);
const AsyncHrLeaveView = lazyComponent(() => import('../../views/selfservice/leave/hr-leave/HrLeaveView'));
const AsyncMyLeaveOverviewView = lazyComponent(
  () => import('../../views/selfservice/leave/leave-overview/LeaveOverviewView')
);
const AsyncOtherLeaveView = lazyComponent(() => import('../../views/selfservice/leave/other-leave/OtherLeaveView'));
const AsyncLeaveDetailsView = lazyComponent(
  () => import('../../views/selfservice/leave/leave-details/LeaveDetailsView')
);
const AsyncLeaveApplicationChoiceView = lazyComponent(
  () => import('../../views/selfservice/leave/leave-application-choice/LeaveApplicationChoiceView')
);
const AsyncHrLeaveApplicationChoiceView = lazyComponent(
  () => import('../../views/selfservice/leave/leave-application-choice/HrLeaveApplicationChoiceView')
);
const AsyncManagerLeaveApplicationChoiceView = lazyComponent(
  () => import('../../views/selfservice/leave/leave-application-choice/ManagerLeaveApplicationChoiceView')
);
const AsyncAnnualLeaveApplicationView = lazyComponent(
  () => import('../../views/selfservice/leave/annual-leave-application/AnnualLeaveApplicationView')
);
const AsyncAnnualLeaveApplicationAddView = lazyComponent(
  () => import('../../views/selfservice/leave/annual-leave-application/add/AnnualLeaveApplicationAddView')
);
const AsyncAnnualLeaveApplicationEditView = lazyComponent(
  () => import('../../views/selfservice/leave/annual-leave-application/edit/AnnualLeaveApplicationEditView')
);
const AsyncHealthDaysLeaveApplicationAddView = lazyComponent(
  () => import('../../views/selfservice/leave/health-days-leave-application/add/HealthDaysLeaveApplicationAddView')
);
const AsyncHealthDaysLeaveApplicationEditView = lazyComponent(
  () => import('../../views/selfservice/leave/health-days-leave-application/edit/HealthDaysLeaveApplicationEditView')
);
const AsyncFuneralLeaveApplicationAddView = lazyComponent(
  () => import('../../views/selfservice/leave/funeral-leave-application/add/FuneralLeaveApplicationAddView')
);
const AsyncFuneralLeaveApplicationEditView = lazyComponent(
  () => import('../../views/selfservice/leave/funeral-leave-application/edit/FuneralLeaveApplicationEditView')
);
const AsyncMarriageLeaveApplicationAddView = lazyComponent(
  () => import('../../views/selfservice/leave/marriage-leave-application/add/MarriageLeaveApplicationAddView')
);
const AsyncMarriageLeaveApplicationEditView = lazyComponent(
  () => import('../../views/selfservice/leave/marriage-leave-application/edit/MarriageLeaveApplicationEditView')
);
const AsyncParentalLeaveApplicationAddView = lazyComponent(
  () => import('../../views/selfservice/leave/parental-leave-application/add/ParentalLeaveApplicationAddView')
);
const AsyncParentalLeaveApplicationEditView = lazyComponent(
  () => import('../../views/selfservice/leave/parental-leave-application/edit/ParentalLeaveApplicationEditView')
);
const AsyncUnpaidLeaveApplicationAddView = lazyComponent(
  () => import('../../views/selfservice/leave/unpaid-leave-application/add/UnpaidLeaveApplicationAddView')
);
const AsyncUnpaidLeaveApplicationEditView = lazyComponent(
  () => import('../../views/selfservice/leave/unpaid-leave-application/edit/UnpaidLeaveApplicationEditView')
);
const AsyncUnpaidMaternityLeaveApplicationAddView = lazyComponent(
  () =>
    import(
      '../../views/selfservice/leave/unpaid-maternity-leave-application/add/UnpaidMaternityLeaveApplicationAddView'
    )
);
const AsyncUnpaidMaternityLeaveApplicationEditView = lazyComponent(
  () =>
    import(
      '../../views/selfservice/leave/unpaid-maternity-leave-application/edit/UnpaidMaternityLeaveApplicationEditView'
    )
);
const AsyncCaretakerLeaveApplicationAddView = lazyComponent(
  () => import('../../views/selfservice/leave/caretaker-leave-application/add/CaretakerLeaveApplicationAddView')
);
const AsyncCaretakerLeaveApplicationEditView = lazyComponent(
  () => import('../../views/selfservice/leave/caretaker-leave-application/edit/CaretakerLeaveApplicationEditView')
);
const AsyncStudyLeaveApplicationAddView = lazyComponent(
  () => import('../../views/selfservice/leave/study-leave-application/add/StudyLeaveApplicationAddView')
);
const AsyncStudyLeaveApplicationEditView = lazyComponent(
  () => import('../../views/selfservice/leave/study-leave-application/edit/StudyLeaveApplicationEditView')
);
const AsyncUnpaidStudyLeaveApplicationAddView = lazyComponent(
  () => import('../../views/selfservice/leave/unpaid-study-leave-application/add/UnpaidStudyLeaveApplicationAddView')
);
const AsyncUnpaidStudyLeaveApplicationEditView = lazyComponent(
  () => import('../../views/selfservice/leave/unpaid-study-leave-application/edit/UnpaidStudyLeaveApplicationEditView')
);
const AsyncDegreeStudyLeaveApplicationAddView = lazyComponent(
  () => import('../../views/selfservice/leave/degree-study-leave-application/add/DegreeStudyLeaveApplicationAddView')
);
const AsyncDegreeStudyLeaveApplicationEditView = lazyComponent(
  () => import('../../views/selfservice/leave/degree-study-leave-application/edit/DegreeStudyLeaveApplicationEditView')
);
const AsyncPowerbiView = lazyComponent(() => import('../../views/powerbi/PowerbiView'));
const AsyncPowerbiReportView = lazyComponent(() => import('../../views/powerbi/components/report/ReportView'));
const AsyncPowerbiManageView = lazyComponent(() => import('../../views/powerbi/components/manage/ManageOverviewView'));
const AsyncPowerbiManageReportView = lazyComponent(
  () => import('../../views/powerbi/components/edit/ManageReportView')
);
const AsyncPowerbiManageCategoriesView = lazyComponent(
  () => import('../../views/powerbi/components/categories/ManageCategoriesView')
);
const AsyncProfileView = lazyComponent(() => import('../../views/profile/ProfileView'));
const AsyncRoomsView = lazyComponent(() => import('../../views/rooms/RoomsView'));
const AsyncRoomsAddViewWrapper = lazyComponent(() => import('../../views/rooms/room-add/RoomAddView'));
const AsyncRoomsDetailedView = lazyComponent(() => import('../../views/rooms/room-details/RoomDetailsView'));
const AsyncRoomsDetailedEditView = lazyComponent(() => import('../../views/rooms/room-edit/RoomEditView'));
const AsyncTrainingView = lazyComponent(() => import('../../views/training/TrainingView'));

const AsyncEmployeeRolesOverviewView = lazyComponent(
  () => import('../../views/selfservice/roles-hub/employee-roles-overview/EmployeeRolesOverviewView')
);
const AsyncMyRolesOverviewView = lazyComponent(
  () => import('../../views/selfservice/roles-hub/my-roles-overview/MyRolesOverviewView')
);
const AsyncRoleDelegationAddView = lazyComponent(
  () => import('../../views/selfservice/roles-hub/delegate-role-application/add/DelegateRoleApplicationAddView')
);

const AsyncRoleDelegationEditView = lazyComponent(
  () => import('../../views/selfservice/roles-hub/delegate-role-application/edit/DelegateRoleApplicationEditView')
);

export const routesList: ICustomRouteProps[] = [
  { path: UNAUTHORIZED, component: <AsyncUnauthorizedView /> },
  { path: CONTACT_DETAILS, component: <AsyncContactView /> },
  { path: [DASHBOARD_TAB_NEWS, DASHBOARD_TAB_TRAININGS, DASHBOARD_ROOT], component: <AsyncDashboardView /> },
  { path: INTRA_TV_ROOT, component: <AsyncIntraTVView />, featureFlagLock: { flag: FEATURE_FLAGS.INTRA_TV } },
  { path: SEARCH_ROOT, component: <AsyncSearchView />, featureFlagLock: { flag: FEATURE_FLAGS.SEARCH_FLOW } },
  { path: POWERBI_ROOT, component: <AsyncPowerbiView /> },
  { path: POWERBI_REPORT, component: <AsyncPowerbiReportView /> },
  {
    path: POWERBI_MANAGE,
    component: <AsyncPowerbiManageView />,
    featureFlagLock: { flag: FEATURE_FLAGS.ALLOW_POWERBI_MANAGE, fallbackRoute: paths.powerbi.root },
  },
  {
    path: POWERBI_MANAGE_REPORT,
    component: <AsyncPowerbiManageReportView />,
    featureFlagLock: { flag: FEATURE_FLAGS.ALLOW_POWERBI_MANAGE, fallbackRoute: paths.powerbi.root },
  },
  {
    path: POWERBI_MANAGE_CATEGORIES,
    component: <AsyncPowerbiManageCategoriesView />,
    featureFlagLock: { flag: FEATURE_FLAGS.ALLOW_POWERBI_MANAGE, fallbackRoute: paths.powerbi.root },
  },
  { path: [SELFSERVICE, SELFSERVICE_ROOT], component: <AsyncSelfServiceViewWrapper />, contractLock: true },
  {
    path: [EXPENSES_ROOT],
    component: <AsyncExpensesRoot />,
    contractLock: true,
    featureFlagLock: { flag: FEATURE_FLAGS.NEW_EXPENSES_FLOW, fallbackRoute: paths.selfService.root() },
  },
  {
    path: [SELFSERVICE_MANAGER_LEAVE_MANAGEMENT, SELFSERVICE_MANAGER_LEAVE_MANAGEMENT_ROOT],
    component: <AsyncManagerLeaveView />,
    roleLock: {
      roles: [...ALL_MANAGER_ROLES, ROLES.HR],
      fallbackRoute: paths.selfService.root(),
    },
    contractLock: true,
  },
  {
    path: [SELFSERVICE_HR_LEAVE_MANAGEMENT, SELFSERVICE_HR_LEAVE_MANAGEMENT_ROOT],
    component: <AsyncHrLeaveView />,
    roleLock: { roles: [ROLES.HR], fallbackRoute: paths.selfService.root() },
    contractLock: true,
  },
  { path: SELFSERVICE_OTHER_LEAVE, component: <AsyncOtherLeaveView />, contractLock: true },
  { path: SELFSERVICE_MY_LEAVE_OVERVIEW, component: <AsyncMyLeaveOverviewView />, contractLock: true },
  { path: SELFSERVICE_EMPLOYEE_LEAVE_OVERVIEW, component: <AsyncMyLeaveOverviewView />, contractLock: true },
  { path: SELFSERVICE_LEAVE_DETAILS, component: <AsyncLeaveDetailsView />, contractLock: true },
  { path: SELFSERVICE_LEAVE_APPLICATION_CHOICE, component: <AsyncLeaveApplicationChoiceView />, contractLock: true },
  {
    path: SELFSERVICE_HR_LEAVE_APPLICATION_CHOICE,
    component: <AsyncHrLeaveApplicationChoiceView />,
    contractLock: true,
    roleLock: { roles: [ROLES.HR], fallbackRoute: paths.selfService.root() },
  },
  {
    path: SELFSERVICE_MANAGER_LEAVE_APPLICATION_CHOICE,
    component: <AsyncManagerLeaveApplicationChoiceView />,
    contractLock: true,
    roleLock: { roles: [...ALL_MANAGER_ROLES, ROLES.HR], fallbackRoute: paths.selfService.root() },
  },
  {
    path: [SELFSERVICE_ANNUAL_LEAVE_APPLICATION, SELFSERVICE_ANNUAL_LEAVE_APPLICATION_NEW],
    component: <AsyncAnnualLeaveApplicationView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_ANNUAL_LEAVE_APPLICATION_ADD],
    component: <AsyncAnnualLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_ANNUAL_LEAVE_APPLICATION_EDIT],
    component: <AsyncAnnualLeaveApplicationEditView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_HEALTH_DAYS_LEAVE_APPLICATION_ADD],
    component: <AsyncHealthDaysLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_HEALTH_DAYS_LEAVE_APPLICATION_EDIT],
    component: <AsyncHealthDaysLeaveApplicationEditView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_FUNERAL_LEAVE_APPLICATION_ADD],
    component: <AsyncFuneralLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_FUNERAL_LEAVE_APPLICATION_EDIT],
    component: <AsyncFuneralLeaveApplicationEditView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_MARRIAGE_LEAVE_APPLICATION_ADD],
    component: <AsyncMarriageLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_MARRIAGE_LEAVE_APPLICATION_EDIT],
    component: <AsyncMarriageLeaveApplicationEditView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_PARENTAL_LEAVE_APPLICATION_ADD],
    component: <AsyncParentalLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_PARENTAL_LEAVE_APPLICATION_EDIT],
    component: <AsyncParentalLeaveApplicationEditView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_UNPAID_LEAVE_APPLICATION_ADD],
    component: <AsyncUnpaidLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_UNPAID_LEAVE_APPLICATION_EDIT],
    component: <AsyncUnpaidLeaveApplicationEditView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_UNPAID_MATERNITY_LEAVE_APPLICATION_ADD],
    component: <AsyncUnpaidMaternityLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_UNPAID_MATERNITY_LEAVE_APPLICATION_EDIT],
    component: <AsyncUnpaidMaternityLeaveApplicationEditView />,
  },
  {
    path: [SELFSERVICE_STUDY_LEAVE_APPLICATION_ADD],
    component: <AsyncStudyLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_STUDY_LEAVE_APPLICATION_EDIT],
    component: <AsyncStudyLeaveApplicationEditView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_UNPAID_STUDY_LEAVE_APPLICATION_ADD],
    component: <AsyncUnpaidStudyLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_UNPAID_STUDY_LEAVE_APPLICATION_EDIT],
    component: <AsyncUnpaidStudyLeaveApplicationEditView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_DEGREE_STUDY_LEAVE_APPLICATION_ADD],
    component: <AsyncDegreeStudyLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_DEGREE_STUDY_LEAVE_APPLICATION_EDIT],
    component: <AsyncDegreeStudyLeaveApplicationEditView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_CARETAKER_LEAVE_APPLICATION_ADD],
    component: <AsyncCaretakerLeaveApplicationAddView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_CARETAKER_LEAVE_APPLICATION_EDIT],
    component: <AsyncCaretakerLeaveApplicationEditView />,
    contractLock: true,
  },
  {
    path: [SELFSERVICE_ROLES_HUB_EMPLOYEE_ROLES_OVERVIEW],
    component: <AsyncEmployeeRolesOverviewView />,
    contractLock: true,
  },
  { path: [SELFSERVICE_ROLES_HUB_MY_ROLES_OVERVIEW], component: <AsyncMyRolesOverviewView /> },
  { path: [SELFSERVICE_ROLES_HUB_DELEGATE_ROLE_ADD], component: <AsyncRoleDelegationAddView />, contractLock: true },
  { path: [SELFSERVICE_ROLES_HUB_DELEGATE_ROLE_EDIT], component: <AsyncRoleDelegationEditView />, contractLock: true },
  { path: [PROFILE_ROOT, PROFILE], component: <AsyncProfileView /> },
  { path: [ROOMS_ROOT, ROOMS], component: <AsyncRoomsView /> },
  { path: ROOMS_ADD, component: <AsyncRoomsAddViewWrapper /> },
  { path: ROOMS_DETAIL, component: <AsyncRoomsDetailedView /> },
  { path: ROOMS_DETAIL_EDIT, component: <AsyncRoomsDetailedEditView /> },
  {
    path: TRAINING_ROOT,
    component: <AsyncTrainingView />,
    featureFlagLock: { flag: FEATURE_FLAGS.NEW_INTERNAL_TRAININGS_FLOW, fallbackRoute: paths.selfService.root() },
  },
];
