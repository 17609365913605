import { IRouteParams as ContactViewRouteParams } from '../../views/contact/ContactView';
import { IRouteParams as PowerbiReportEditViewRouteParams } from '../../views/powerbi/components/edit/ManageReportView';
import { IRouteParams as PowerbiReportViewRouteParams } from '../../views/powerbi/components/report/ReportView';
import { IRouteParams as ProfileViewRouteParams } from '../../views/profile/ProfileView';
import { IRouteParams as RoomsDetailsViewRouteParams } from '../../views/rooms/room-details/RoomDetailsView';
import { IRouteParams as RoomsDetailsEditViewRouteParams } from '../../views/rooms/room-edit/RoomEditView';
import { IRouteParams as RoomsViewRouteParams } from '../../views/rooms/RoomsView';
import { IRouteParams as AnnualLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/annual-leave-application/add/AnnualLeaveApplicationAddView';
import { IRouteParams as AnnualLeaveApplicationViewRouteParams } from '../../views/selfservice/leave/annual-leave-application/AnnualLeaveApplicationView';
import { IRouteParams as AnnualLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/annual-leave-application/edit/AnnualLeaveApplicationEditView';
import { IRouteParams as CaretakerLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/caretaker-leave-application/add/CaretakerLeaveApplicationAddView';
import { IRouteParams as CaretakerLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/caretaker-leave-application/edit/CaretakerLeaveApplicationEditView';
import { IRouteParams as DegreeStudyLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/degree-study-leave-application/add/DegreeStudyLeaveApplicationAddView';
import { IRouteParams as DegreeStudyLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/degree-study-leave-application/edit/DegreeStudyLeaveApplicationEditView';
import { IRouteParams as FuneralLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/funeral-leave-application/add/FuneralLeaveApplicationAddView';
import { IRouteParams as FuneralLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/funeral-leave-application/edit/FuneralLeaveApplicationEditView';
import { IRouteParams as HealthDaysLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/health-days-leave-application/add/HealthDaysLeaveApplicationAddView';
import { IRouteParams as HealthDaysLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/health-days-leave-application/edit/HealthDaysLeaveApplicationEditView';
import { IRouteParams as HrLeaveViewRouteParams } from '../../views/selfservice/leave/hr-leave/HrLeaveView';
import { IRouteParams as LeaveDetailsViewRouteParams } from '../../views/selfservice/leave/leave-details/LeaveDetailsView';
import { IRouteParams as LeaveOverviewViewRouteParams } from '../../views/selfservice/leave/leave-overview/LeaveOverviewView';
import { IRouteParams as ManagerLeaveViewRouteParams } from '../../views/selfservice/leave/manager-leave/ManagerLeaveView';
import { IRouteParams as MarriageLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/marriage-leave-application/add/MarriageLeaveApplicationAddView';
import { IRouteParams as MarriageLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/marriage-leave-application/edit/MarriageLeaveApplicationEditView';
import { IRouteParams as ParentalLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/parental-leave-application/add/ParentalLeaveApplicationAddView';
import { IRouteParams as ParentalLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/parental-leave-application/edit/ParentalLeaveApplicationEditView';
import { IRouteParams as StudyLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/study-leave-application/add/StudyLeaveApplicationAddView';
import { IRouteParams as StudyLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/study-leave-application/edit/StudyLeaveApplicationEditView';
import { IRouteParams as UnpaidLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/unpaid-leave-application/add/UnpaidLeaveApplicationAddView';
import { IRouteParams as UnpaidLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/unpaid-leave-application/edit/UnpaidLeaveApplicationEditView';
import { IRouteParams as UnpaidMaternityLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/unpaid-maternity-leave-application/add/UnpaidMaternityLeaveApplicationAddView';
import { IRouteParams as UnpaidMaternityLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/unpaid-maternity-leave-application/edit/UnpaidMaternityLeaveApplicationEditView';
import { IRouteParams as UnpaidStudyLeaveApplicationAddViewRouteParams } from '../../views/selfservice/leave/unpaid-study-leave-application/add/UnpaidStudyLeaveApplicationAddView';
import { IRouteParams as UnpaidStudyLeaveApplicationEditViewRouteParams } from '../../views/selfservice/leave/unpaid-study-leave-application/edit/UnpaidStudyLeaveApplicationEditView';
import { IRouteParams as DelegateRoleApplicationEditViewRouteParams } from '../../views/selfservice/roles-hub/delegate-role-application/edit/DelegateRoleApplicationEditView';
import { IRouteParams as SelfServiceViewRouteParams } from '../../views/selfservice/SelfServiceView';
import { generateUrl } from '../utils/urlsUtil';

export const UNAUTHORIZED = `/unauthorized` as const;

/**
 * DASHBOARD ROUTES
 */
export const DASHBOARD_ROOT = `/` as const;
export const DASHBOARD_TAB_NEWS = `/tab-news` as const; // fallback when tabs were in path params
export const DASHBOARD_TAB_TRAININGS = `/tab-trainings` as const; // fallback when tabs were in path params

/**
 * PROFILE ROUTES
 */
export const CONTACT_DETAILS = `/contact/:employee_id` as const;

/**
 * Intra-TV routes
 */
export const INTRA_TV_ROOT = `/intra-tv` as const;

/**
 * SEARCH ROUTES
 */
export const SEARCH_ROOT = `/search` as const;

/**
 * SELF-SERVICE ROUTES
 */
export const SELFSERVICE_ROOT = `/self-service` as const;
export const SELFSERVICE = `${SELFSERVICE_ROOT}/:tabId` as const;

/**
 * LEAVE ROUTES
 */
export const SELFSERVICE_MY_LEAVE_OVERVIEW = `${SELFSERVICE_ROOT}/leave-overview` as const;
export const SELFSERVICE_EMPLOYEE_LEAVE_OVERVIEW = `${SELFSERVICE_ROOT}/leave-overview/:employeeId` as const;
export const SELFSERVICE_OTHER_LEAVE = `${SELFSERVICE_ROOT}/leave-overview/other-leave` as const;
export const SELFSERVICE_MANAGER_LEAVE_MANAGEMENT_ROOT = `${SELFSERVICE_ROOT}/manager-leave-management` as const;
export const SELFSERVICE_MANAGER_LEAVE_MANAGEMENT = `${SELFSERVICE_ROOT}/manager-leave-management/:tabId` as const;
export const SELFSERVICE_HR_LEAVE_MANAGEMENT_ROOT = `${SELFSERVICE_ROOT}/hr-leave-management` as const;
export const SELFSERVICE_HR_LEAVE_MANAGEMENT = `${SELFSERVICE_ROOT}/hr-leave-management/:tabId` as const;
export const SELFSERVICE_LEAVE_DETAILS = `${SELFSERVICE_ROOT}/leave/:leaveId` as const;
export const SELFSERVICE_ANNUAL_LEAVE_APPLICATION_NEW = `${SELFSERVICE_ROOT}/application/annual-leave` as const;
export const SELFSERVICE_ANNUAL_LEAVE_APPLICATION = `${SELFSERVICE_ROOT}/application/annual-leave/:leaveId` as const;
export const SELFSERVICE_ANNUAL_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/annual-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_ANNUAL_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/annual-leave/edit/:leaveId` as const;
export const SELFSERVICE_HEALTH_DAYS_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/health-days-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_HEALTH_DAYS_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/health-days-leave/edit/:leaveId` as const;
export const SELFSERVICE_FUNERAL_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/funeral-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_FUNERAL_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/funeral-leave/edit/:leaveId` as const;
export const SELFSERVICE_MARRIAGE_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/marriage-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_MARRIAGE_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/marriage-leave/edit/:leaveId` as const;
export const SELFSERVICE_PARENTAL_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/parental-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_PARENTAL_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/parental-leave/edit/:leaveId` as const;
export const SELFSERVICE_UNPAID_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/unpaid-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_UNPAID_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/unpaid-leave/edit/:leaveId` as const;
export const SELFSERVICE_UNPAID_MATERNITY_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/unpaid-maternity-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_UNPAID_MATERNITY_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/unpaid-maternity-leave/edit/:leaveId` as const;
export const SELFSERVICE_CARETAKER_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/caretaker-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_CARETAKER_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/caretaker-leave/edit/:leaveId` as const;
export const SELFSERVICE_STUDY_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/study-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_STUDY_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/study-leave/edit/:leaveId` as const;
export const SELFSERVICE_DEGREE_STUDY_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/degree-study-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_DEGREE_STUDY_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/degree-study-leave/edit/:leaveId` as const;
export const SELFSERVICE_UNPAID_STUDY_LEAVE_APPLICATION_ADD =
  `${SELFSERVICE_ROOT}/application/unpaid-study-leave/add/:employeeNumber?` as const;
export const SELFSERVICE_UNPAID_STUDY_LEAVE_APPLICATION_EDIT =
  `${SELFSERVICE_ROOT}/application/unpaid-study-leave/edit/:leaveId` as const;
export const SELFSERVICE_LEAVE_APPLICATION_CHOICE = `${SELFSERVICE_ROOT}/application` as const;
export const SELFSERVICE_HR_LEAVE_APPLICATION_CHOICE = `${SELFSERVICE_ROOT}/hr-application` as const;
export const SELFSERVICE_MANAGER_LEAVE_APPLICATION_CHOICE = `${SELFSERVICE_ROOT}/manager-application` as const;

/**
 * SECONDMENTS ROUTES
 */
export const SELFSERVICE_MY_SECONDMENTS_OVERVIEW = `${SELFSERVICE_ROOT}/secondments-overview` as const;
export const SELFSERVICE_EMPLOYEE_SECONDMENTS_OVERVIEW =
  `${SELFSERVICE_ROOT}/secondments-overview/:employeeId` as const;
export const SELFSERVICE_MANAGER_SECONDMENTS_MANAGEMENT = `${SELFSERVICE_ROOT}/manager-secondments-management` as const;
export const SELFSERVICE_HR_SECONDMENTS_MANAGEMENT = `${SELFSERVICE_ROOT}/hr-secondments-management` as const;
export const SELFSERVICE_SECONDMENTS_APPLICATION_NEW = `${SELFSERVICE_ROOT}/application/secondments` as const;
export const SELFSERVICE_SECONDMENTS_APPLICATION = `${SELFSERVICE_ROOT}/application/secondments/:secondmentId` as const;

/**
 * ROOMS ROUTES
 */
export const ROOMS_ROOT = '/rooms' as const;
export const ROOMS = `${ROOMS_ROOT}/:tabId` as const;
export const ROOMS_ADD = `${ROOMS_ROOT}/add` as const;
export const ROOMS_DETAIL = `${ROOMS_ROOT}/room/:roomId` as const;
export const ROOMS_DETAIL_EDIT = `${ROOMS_ROOT}/room/:roomId/edit` as const;

/**
 * POWER-BI ROUTES
 */
export const POWERBI_ROOT = `/powerbi` as const;
export const POWERBI_REPORT = `${POWERBI_ROOT}/report/:reportId` as const;
export const POWERBI_MANAGE = `${POWERBI_ROOT}/manage` as const;
export const POWERBI_MANAGE_REPORT = `${POWERBI_ROOT}/manage/report/:reportId` as const;
export const POWERBI_MANAGE_CATEGORIES = `${POWERBI_ROOT}/manage/categories` as const;

/**
 * PROFILE ROUTES
 */
export const PROFILE_ROOT = `/profile` as const;
export const PROFILE = `${PROFILE_ROOT}/:tabId` as const;

/**
 * MODULE ROUTES
 * All the sub routes are defined inside the module
 * TODO convert all other existing modules to use internal routes based on expenses example
 */
export const EXPENSES_ROOT = `${SELFSERVICE_ROOT}/expenses/*` as const;

/**
 * TRAINING ROUTES
 */
export const TRAINING_ROOT = `/training` as const;

/**
 * ROLES HUB ROUTES
 */
export const SELFSERVICE_ROLES_HUB_ROOT = `${SELFSERVICE_ROOT}/roles-hub`;
export const SELFSERVICE_ROLES_HUB_EMPLOYEE_ROLES_OVERVIEW = `${SELFSERVICE_ROLES_HUB_ROOT}/employee-roles` as const;
export const SELFSERVICE_ROLES_HUB_MY_ROLES_OVERVIEW = `${SELFSERVICE_ROLES_HUB_ROOT}/my-roles` as const;
export const SELFSERVICE_ROLES_HUB_DELEGATE_ROLE_ADD = `${SELFSERVICE_ROLES_HUB_ROOT}/delegate-role/add` as const;
export const SELFSERVICE_ROLES_HUB_DELEGATE_ROLE_EDIT =
  `${SELFSERVICE_ROLES_HUB_ROOT}/delegate-role/edit/:authorizationId` as const;

// Route paths
// NB! when changing route urls, make sure the breadcrumb translations are also changed(common/translation/locales/<en|et>/general.json)
export const paths = {
  dashboard: () => generateUrl(DASHBOARD_ROOT),
  contact: (p: ContactViewRouteParams) => generateUrl(CONTACT_DETAILS, p),
  intraTV: INTRA_TV_ROOT,
  search: () => generateUrl(SEARCH_ROOT),
  selfService: {
    root: (p?: SelfServiceViewRouteParams) => generateUrl(p ? SELFSERVICE : SELFSERVICE_ROOT, p),
    rolesHub: {
      employeeRolesOverview: () => generateUrl(SELFSERVICE_ROLES_HUB_EMPLOYEE_ROLES_OVERVIEW),
      myRolesOverview: () => generateUrl(SELFSERVICE_ROLES_HUB_MY_ROLES_OVERVIEW),
      roleDelegationForm: {
        add: () => generateUrl(SELFSERVICE_ROLES_HUB_DELEGATE_ROLE_ADD),
        edit: (p?: DelegateRoleApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_ROLES_HUB_DELEGATE_ROLE_EDIT, p),
      },
    },
    leave: {
      leaveOverview: (p?: LeaveOverviewViewRouteParams) =>
        generateUrl(p ? SELFSERVICE_EMPLOYEE_LEAVE_OVERVIEW : SELFSERVICE_MY_LEAVE_OVERVIEW, p),
      otherLeave: SELFSERVICE_OTHER_LEAVE,
      managerLeaveManagement: (p?: ManagerLeaveViewRouteParams) =>
        generateUrl(p ? SELFSERVICE_MANAGER_LEAVE_MANAGEMENT : SELFSERVICE_MANAGER_LEAVE_MANAGEMENT_ROOT, p),
      hrLeaveManagement: (p?: HrLeaveViewRouteParams) =>
        generateUrl(p ? SELFSERVICE_HR_LEAVE_MANAGEMENT : SELFSERVICE_HR_LEAVE_MANAGEMENT_ROOT, p),
      leaveDetails: (p: LeaveDetailsViewRouteParams) => generateUrl(SELFSERVICE_LEAVE_DETAILS, p),
      annualLeaveApplication: (p?: AnnualLeaveApplicationViewRouteParams) =>
        generateUrl(p ? SELFSERVICE_ANNUAL_LEAVE_APPLICATION : SELFSERVICE_ANNUAL_LEAVE_APPLICATION_NEW, p),
      annualLeaveApplicationNew: {
        add: (p?: AnnualLeaveApplicationAddViewRouteParams) => generateUrl(SELFSERVICE_ANNUAL_LEAVE_APPLICATION_ADD, p),
        edit: (p?: AnnualLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_ANNUAL_LEAVE_APPLICATION_EDIT, p),
      },
      healthDaysLeaveApplication: {
        add: (p?: HealthDaysLeaveApplicationAddViewRouteParams) =>
          generateUrl(SELFSERVICE_HEALTH_DAYS_LEAVE_APPLICATION_ADD, p),
        edit: (p?: HealthDaysLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_HEALTH_DAYS_LEAVE_APPLICATION_EDIT, p),
      },
      funeralLeaveApplication: {
        add: (p?: FuneralLeaveApplicationAddViewRouteParams) =>
          generateUrl(SELFSERVICE_FUNERAL_LEAVE_APPLICATION_ADD, p),
        edit: (p?: FuneralLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_FUNERAL_LEAVE_APPLICATION_EDIT, p),
      },
      marriageLeaveApplication: {
        add: (p?: MarriageLeaveApplicationAddViewRouteParams) =>
          generateUrl(SELFSERVICE_MARRIAGE_LEAVE_APPLICATION_ADD, p),
        edit: (p?: MarriageLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_MARRIAGE_LEAVE_APPLICATION_EDIT, p),
      },
      parentalLeaveApplication: {
        add: (p?: ParentalLeaveApplicationAddViewRouteParams) =>
          generateUrl(SELFSERVICE_PARENTAL_LEAVE_APPLICATION_ADD, p),
        edit: (p?: ParentalLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_PARENTAL_LEAVE_APPLICATION_EDIT, p),
      },
      unpaidLeaveApplication: {
        add: (p?: UnpaidLeaveApplicationAddViewRouteParams) => generateUrl(SELFSERVICE_UNPAID_LEAVE_APPLICATION_ADD, p),
        edit: (p?: UnpaidLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_UNPAID_LEAVE_APPLICATION_EDIT, p),
      },
      unpaidMaternityLeaveApplication: {
        add: (p?: UnpaidMaternityLeaveApplicationAddViewRouteParams) =>
          generateUrl(SELFSERVICE_UNPAID_MATERNITY_LEAVE_APPLICATION_ADD, p),
        edit: (p?: UnpaidMaternityLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_UNPAID_MATERNITY_LEAVE_APPLICATION_EDIT, p),
      },
      caretakerLeaveApplication: {
        add: (p?: CaretakerLeaveApplicationAddViewRouteParams) =>
          generateUrl(SELFSERVICE_CARETAKER_LEAVE_APPLICATION_ADD, p),
        edit: (p?: CaretakerLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_CARETAKER_LEAVE_APPLICATION_EDIT, p),
      },
      studyLeaveApplication: {
        add: (p?: StudyLeaveApplicationAddViewRouteParams) => generateUrl(SELFSERVICE_STUDY_LEAVE_APPLICATION_ADD, p),
        edit: (p?: StudyLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_STUDY_LEAVE_APPLICATION_EDIT, p),
      },
      degreeStudyLeaveApplication: {
        add: (p?: DegreeStudyLeaveApplicationAddViewRouteParams) =>
          generateUrl(SELFSERVICE_DEGREE_STUDY_LEAVE_APPLICATION_ADD, p),
        edit: (p?: DegreeStudyLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_DEGREE_STUDY_LEAVE_APPLICATION_EDIT, p),
      },
      unpaidStudyLeaveApplication: {
        add: (p?: UnpaidStudyLeaveApplicationAddViewRouteParams) =>
          generateUrl(SELFSERVICE_UNPAID_STUDY_LEAVE_APPLICATION_ADD, p),
        edit: (p?: UnpaidStudyLeaveApplicationEditViewRouteParams) =>
          generateUrl(SELFSERVICE_UNPAID_STUDY_LEAVE_APPLICATION_EDIT, p),
      },
      leaveApplicationChoice: () => generateUrl(SELFSERVICE_LEAVE_APPLICATION_CHOICE),
      hrLeaveApplicationChoice: () => generateUrl(SELFSERVICE_HR_LEAVE_APPLICATION_CHOICE),
      managerLeaveApplicationChoice: () => generateUrl(SELFSERVICE_MANAGER_LEAVE_APPLICATION_CHOICE),
    },
  },
  rooms: {
    root: (p?: RoomsViewRouteParams) => generateUrl(p ? ROOMS : ROOMS_ROOT, p),
    add: () => generateUrl(ROOMS_ADD),
    details: (p: RoomsDetailsViewRouteParams) => generateUrl(ROOMS_DETAIL, p),
    edit: (p: RoomsDetailsEditViewRouteParams) => generateUrl(ROOMS_DETAIL_EDIT, p),
  },
  powerbi: {
    root: POWERBI_ROOT,
    report: (p: PowerbiReportViewRouteParams) => generateUrl(POWERBI_REPORT, p),
    manage: POWERBI_MANAGE,
    editReport: (p: PowerbiReportEditViewRouteParams) => generateUrl(POWERBI_MANAGE_REPORT, p),
    editCategories: POWERBI_MANAGE_CATEGORIES,
  },
  profile: {
    root: (p?: ProfileViewRouteParams) => generateUrl(p ? PROFILE : PROFILE_ROOT, p),
  },
  unauthorized: UNAUTHORIZED,
};
