import { CustomReducerFactory } from '../../common/utils/CustomReducerFactory';
import { LoadableData } from '../../common/utils/LoadableData';
import {
  IAccountantBusinessTripsResponse,
  IManagerAppraisalsResponse,
  IManagerBusinessTripsResponse,
  IManagerExpensesResponse,
  IManagerInvoicesResponse,
  IManagerSecondmentsResponse,
  IMyAppraisalsResponse,
  IMyBusinessTripsResponse,
  IMyExpensesResponse,
  IMyInvoicesResponse,
  IMySecondmentsResponse,
  IMyServicesResponse,
} from '../../services/controllers/self-service/SelfServiceTypes';
import {
  LoadAccountantBusinessTripsActions,
  LoadManagerAppraisalsActions,
  LoadManagerBusinessTripsActions,
  LoadManagerExpensesActions,
  LoadManagerInvoicesActions,
  LoadManagerSecondmentsActions,
  LoadMyAppraisalsActions,
  LoadMyBusinessTripsActions,
  LoadMyExpensesActions,
  LoadMyInvoicesActions,
  LoadMySecondmentsActions,
  LoadMyServicesActions,
} from './SelfServiceViewActions';

class State {
  loadableMySecondmentsDto = new LoadableData<IMySecondmentsResponse>();
  loadableMyBusinessTripsDto = new LoadableData<IMyBusinessTripsResponse>();
  loadableMyExpensesDto = new LoadableData<IMyExpensesResponse>();
  loadableMyServicesDto = new LoadableData<IMyServicesResponse>();
  loadableMyAppraisalsDto = new LoadableData<IMyAppraisalsResponse>();
  loadableMyInvoicesDto = new LoadableData<IMyInvoicesResponse>();
  loadableManagerSecondmentsDto = new LoadableData<IManagerSecondmentsResponse>();
  loadableManagerBusinessTripsDto = new LoadableData<IManagerBusinessTripsResponse>();
  loadableManagerExpensesDto = new LoadableData<IManagerExpensesResponse>();
  loadableManagerAppraisalsDto = new LoadableData<IManagerAppraisalsResponse>();
  loadableManagerInvoicesDto = new LoadableData<IManagerInvoicesResponse>();
  loadableAccountantBusinessTripsDto = new LoadableData<IAccountantBusinessTripsResponse>();
}

export default new CustomReducerFactory(new State())
  .addLoadableReducer(LoadMySecondmentsActions, 'loadableMySecondmentsDto')
  .addLoadableReducer(LoadMyBusinessTripsActions, 'loadableMyBusinessTripsDto')
  .addLoadableReducer(LoadMyExpensesActions, 'loadableMyExpensesDto')
  .addLoadableReducer(LoadMyServicesActions, 'loadableMyServicesDto')
  .addLoadableReducer(LoadMyAppraisalsActions, 'loadableMyAppraisalsDto')
  .addLoadableReducer(LoadMyInvoicesActions, 'loadableMyInvoicesDto')
  .addLoadableReducer(LoadManagerSecondmentsActions, 'loadableManagerSecondmentsDto')
  .addLoadableReducer(LoadManagerBusinessTripsActions, 'loadableManagerBusinessTripsDto')
  .addLoadableReducer(LoadManagerExpensesActions, 'loadableManagerExpensesDto')
  .addLoadableReducer(LoadManagerAppraisalsActions, 'loadableManagerAppraisalsDto')
  .addLoadableReducer(LoadManagerInvoicesActions, 'loadableManagerInvoicesDto')
  .addLoadableReducer(LoadAccountantBusinessTripsActions, 'loadableAccountantBusinessTripsDto')
  .toReducer();

export { State as SelfServiceState };
