import { EventType, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ConfigProvider, Loader } from 'taltech-styleguide';

import { Anchor } from '../../../components/anchor/Anchor';
import UnauthorizedView from '../../../views/unauthorized/UnauthorizedView';
import { LANGUAGES } from '../../constants/locale';
import { authParameters } from '../azureAuth';

interface IAuthenticationWrapperProps {
  children?: React.ReactNode | React.ReactNode[];
}

const AuthenticationWrapper = React.memo(({ children }: IAuthenticationWrapperProps) => {
  const { instance } = useMsal();
  const { i18n } = useTranslation();
  const [fullyRedirected, setFullyRedirected] = useState(false);

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.HANDLE_REDIRECT_END) {
        setFullyRedirected(true); // don't render app before auth has fully redirected
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  const renderConfigProvider = useMemo(
    () => <ConfigProvider locale={i18n.language as LANGUAGES}>{children}</ConfigProvider>,
    [children, i18n.language]
  );

  if (window.Cypress) {
    return <>{renderConfigProvider}</>;
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authParameters}
      errorComponent={() => (
        <UnauthorizedView
          text={
            <Trans
              i18nKey="general:errors.unknown-auth"
              components={[
                <Anchor as="a" href="mailto:helpdesk@taltech.ee">
                  helpdesk@taltech.ee
                </Anchor>,
              ]}
            />
          }
        />
      )}
      loadingComponent={() => <Loader fullScreen />}
    >
      {fullyRedirected ? renderConfigProvider : <Loader fullScreen />}
    </MsalAuthenticationTemplate>
  );
});

export default AuthenticationWrapper;
