import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import i18next from 'i18next';
import { toast } from 'taltech-styleguide';

import { apiBase, CACHE_TAGS } from '../api/Api.utils';
import { LANGUAGES } from '../constants/locale';
import { IRootState } from '../store/storeConfig';
import { isAppEn, isAppEt } from '../utils/environmentUtil';
import { IWhoAmIDto, USER_PREFS, USER_PREFS_ACTION_TYPES } from './AuthTypes';

interface IUserPrefsRequestGeneric<T extends USER_PREFS> {
  user_prefs: T;
  action: USER_PREFS_ACTION_TYPES;
  data: IWhoAmIDto['user_prefs'][T];
}
export type IUserPrefsRequest =
  | IUserPrefsRequestGeneric<USER_PREFS.AVATAR>
  | IUserPrefsRequestGeneric<USER_PREFS.COLLAPSED>
  | IUserPrefsRequestGeneric<USER_PREFS.FOOD_MENU_FAVOURITES>
  | IUserPrefsRequestGeneric<USER_PREFS.BIRTHDAY>
  | IUserPrefsRequestGeneric<USER_PREFS.VEHICLES>
  | IUserPrefsRequestGeneric<USER_PREFS.INTRODUCTION>;
export interface IUserPrefsResponse {
  message: string;
  status: number;
}
interface getEmployeeProfileRequest {
  employeeId?: string;
  bypassMockedUserData?: boolean;
}

export const authApi = apiBase.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getWhoAmI: builder.query<IWhoAmIDto, void>({
      providesTags: [CACHE_TAGS.WHO_AM_I],
      async queryFn(arg, api, extraOptions, fetchWithBQ) {
        localStorage.removeItem('lang'); // clear lang first, since it's already set in i18next setup

        const response = (await fetchWithBQ({
          url: `/v1/2V6C7Q/profile`,
          method: 'POST',
        })) as QueryReturnValue<IWhoAmIDto, FetchBaseQueryError>;

        if (response.data?.lang && i18next.language !== response.data?.lang) {
          await i18next.changeLanguage(response.data?.lang); // reset i18n lang from API
        }

        // if site is served inside TalTech mobile app webview and the sites language doesn't match the apps language
        if ((isAppEt && i18next.language !== LANGUAGES.ET) || (isAppEn && i18next.language !== LANGUAGES.EN)) {
          await api.dispatch(authApi.endpoints.setLang.initiate(undefined, { fixedCacheKey: 'track-language-change' }));
        }

        return response;
      },
      extraOptions: {
        onError: (e) => {
          if (e.status !== 401) {
            toast.error(i18next.t('general:errors.load.profile'), {
              autoClose: 10000,
            });
          }
        },
      },
    }),
    getEmployeeProfile: builder.query<IWhoAmIDto, getEmployeeProfileRequest>({
      async queryFn(request, api, extraOptions, fetchWithBQ) {
        const { employeeId, bypassMockedUserData } = request;
        const myProfile: any = authApi.endpoints.getWhoAmI.select()(api.getState() as IRootState);

        if (!employeeId || myProfile?.data?.employeeID === employeeId) {
          return myProfile;
        } else {
          return (await fetchWithBQ({
            url: `/v1/2V6C7Q/profile`,
            method: 'POST',
            body: {
              employeeId,
              do_not_switch: !!bypassMockedUserData,
            },
          })) as QueryReturnValue<IWhoAmIDto, FetchBaseQueryError>;
        }
      },
      extraOptions: {
        onErrorString: () => i18next.t('general:errors.load.profile'),
      },
    }),
    updateUserPrefs: builder.mutation<IUserPrefsResponse, IUserPrefsRequest>({
      invalidatesTags: [CACHE_TAGS.WHO_AM_I],
      query: (req) => ({
        url: `/v1/2V6C7Q/profile`,
        method: 'POST',
        body: req,
      }),
      // update checked state in the cache straight away
      onQueryStarted(req, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          authApi.util.updateQueryData('getWhoAmI', undefined, (draft) => {
            draft.user_prefs[req.user_prefs] = req.data as any;
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
      extraOptions: {
        onErrorString: () => i18next.t('general:errors.setting-user-pref'),
      },
    }),
    setLang: builder.mutation<void, LANGUAGES | void>({
      query: (req) => ({
        url: `/v1/2V6C7Q/profile`,
        method: 'POST',
        body: { setlang: req || (i18next.language === LANGUAGES.ET ? LANGUAGES.EN : LANGUAGES.ET) },
      }),
      extraOptions: {
        reload: true,
        onErrorString: () => i18next.t('dashboard:errors.load.switch-lang'),
      },
    }),
  }),
});

export const {
  useSetLangMutation,
  useLazyGetEmployeeProfileQuery,
  useGetEmployeeProfileQuery,
  useUpdateUserPrefsMutation,
} = authApi;
