import { STATUS_TYPE } from 'taltech-styleguide';

export type IFeatureFlags = { [featureFlag: string]: boolean };

export interface IMenuLinkDto {
  label: string;
  to: string;
  openInTab?: boolean;
  active?: boolean;
}

export interface IFooterLinkDto {
  title: string;
  href: string;
  target: string;
}

export type IMenuLinks = Array<
  IMenuLinkDto & {
    children?: Array<
      Omit<IMenuLinkDto, 'active'> & {
        children?: Array<
          Pick<IMenuLinkDto, 'label'> & {
            children: Array<Omit<IMenuLinkDto, 'active'>>;
          }
        >;
        topLinks?: Array<Omit<IMenuLinkDto, 'active'>>;
      }
    >;
  }
>;

export const NOTICE_TYPE_MAP = {
  RED: STATUS_TYPE.DANGER,
  YELLOW: STATUS_TYPE.WARNING,
  BLUE: STATUS_TYPE.INFO,
  GREEN: STATUS_TYPE.SUCCESS,
};
