import intersection from 'lodash-es/intersection';
import isArray from 'lodash-es/isArray';
import isPlainObject from 'lodash-es/isPlainObject';

import { useWhoAmIDto } from '../../common/auth/Auth.hooks';
import { IWhoAmIDto } from '../../common/auth/AuthTypes';
import { FEATURE_FLAGS } from '../../common/constants/featureFlags';
import { useGetFeatureFlagsQuery } from '../../common/store/GeneralApiStore';

interface IFeatureFlagProps {
  children?: any;
  flag: FEATURE_FLAGS;
  fallback?: JSX.Element;
}

const FeatureFlagGuard: React.FC<IFeatureFlagProps> = ({ children, flag, fallback }) => {
  const { whoAmIDto } = useWhoAmIDto();
  const { data: featureFlags } = useGetFeatureFlagsQuery();

  return flagComparison(flag, featureFlags, whoAmIDto) ? children : !!fallback ? fallback : null;
};

export default FeatureFlagGuard;

/**
 * Comparison function that determines if flag is passable
 * Feature flags have following possibilities
 *   true/false - On of off for everyone
 *   Array<string> - Array of uniID-s (on for those users)
 *   {
 *     uniID: Array<string>; - Array of supported uniIDs
 *     employeeID: Array<string>; - Array of supported employeeIds
 *     memberOf: Array<string>>; - Array of supported Azure groups
 *   } - Object of comparison properties. Currently supports only uniIDs, employeeIDs and memberOf
 *
 */
export const flagComparison = (
  flag: FEATURE_FLAGS,
  flags?: { [flag: string]: boolean | string[] | { [key: string]: string[] } },
  profile?: IWhoAmIDto
): boolean => {
  if (flags) {
    if (typeof flags[flag] === 'boolean') {
      // condition is boolean
      return !!flags[flag];
    } else if (isArray(flags[flag]) && profile) {
      // condition is array of uniIDs
      return (flags[flag] as string[]).includes(profile?.login_name);
    } else if (isPlainObject(flags[flag]) && profile) {
      // object of different conditions. at least one has to be true for current user
      const flagsObj = flags[flag] as { [key: string]: string[] };
      return (
        flagsObj?.uniID?.includes(profile?.login_name) ||
        flagsObj?.employeeID?.includes(profile?.employeeID) ||
        intersection(flagsObj?.memberOf, profile?.memberof)?.length >= 1
      );
    }
  }

  return false;
};
