import { useCallback, useMemo } from 'react';

import { flagComparison } from '../../components/feature-flag/FeatureFlagGuard';
import { FEATURE_FLAGS } from '../constants/featureFlags';
import { generalApi } from '../store/GeneralApiStore';
import { authApi } from './AuthApiStore';
import { USER_PREFS } from './AuthTypes';

/**
 * User whoAmI info
 */
export const useWhoAmIDto = () => {
  // we query state since the data is already loaded on app start
  const {
    data: whoAmIDto,
    currentData: currentWhoAmIDto,
    isLoading: isWhoAmILoading,
    isFetching: isWhoAmIFetching,
    isUninitialized: isWhoAmIUninitialized,
    isSuccess: isWhoAmISuccess,
    isError: isWhoAmIError,
    error: whoAmIError,
  } = authApi.endpoints.getWhoAmI.useQueryState();

  const myContract = useMemo(
    () => (whoAmIDto?.contracts?.length ? whoAmIDto?.contracts[0] : undefined),
    [whoAmIDto?.contracts]
  );
  const myPrefs = useMemo(() => whoAmIDto?.user_prefs, [whoAmIDto?.user_prefs]);
  const myRoles = useMemo(() => whoAmIDto?.roles, [whoAmIDto?.roles]);
  const isMemberOf = useCallback((group: string) => !!whoAmIDto?.memberof?.includes(group), [whoAmIDto?.memberof]);

  return {
    whoAmIDto,
    currentWhoAmIDto,
    myContract,
    myRoles,
    myPrefs,
    isMemberOf,
    isWhoAmILoading,
    isWhoAmIFetching,
    isWhoAmIUninitialized,
    isWhoAmISuccess,
    isWhoAmIError,
    whoAmIError,
  };
};

/**
 * Simplifies the usage of userPrefs from whoAmIDto
 */
export const useMyPrefs = <T extends USER_PREFS>(key: T) => {
  const { myPrefs } = useWhoAmIDto();

  return myPrefs?.[key];
};

/**
 * Check for feature flag
 */
export const useFeatureFlag = (flag: FEATURE_FLAGS) => {
  // we query state since the data is already loaded on app start
  const { data } = generalApi.endpoints.getFeatureFlags.useQueryState();
  const { whoAmIDto } = useWhoAmIDto();

  return flagComparison(flag, data, whoAmIDto);
};
