import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

import { generalApi } from '../store/GeneralApiStore';
import type { IRootState } from '../store/storeConfig';
import { authApi } from './AuthApiStore';
import { USER_PREFS, USER_PREFS_ACTION_TYPES } from './AuthTypes';
import { msalInstance } from './azureAuth';

/**
 * Calls for all required state that the app needs to function
 */
export const initAuth = createAsyncThunk<void, void, { state: IRootState }>(
  `auth/initAuth`,
  async (_, { dispatch }) => {
    // these requests are required to complete before rendering any routes
    dispatch(generalApi.endpoints.getFeatureFlags.initiate());
    dispatch(authApi.endpoints.getWhoAmI.initiate());
  }
);

/**
 * Logs the user out
 */
export const logOut = () => {
  localStorage.removeItem('lang');
  localStorage.removeItem('msal.last_user');
  msalInstance.setActiveAccount(null);
  msalInstance.logoutRedirect().catch((e) => {
    Sentry.withScope((scope) => {
      scope.setTag('auth_error', 'logout_redirect_error');
      Sentry.captureException(e);
    });
  });
};

/**
 * Send food menu favourites state to the BE-s user preferences
 * TODO move this to DashboardStore when we have created one
 */
export const setFoodMenuFavourites = createAsyncThunk<void, string[], { state: IRootState }>(
  `auth/setFoodMenuFavourites`,
  async (args, { getState, dispatch }) => {
    const currentUserPrefs = authApi.endpoints.getWhoAmI.select()(getState()).data?.user_prefs;

    if (currentUserPrefs) {
      dispatch(
        authApi.endpoints.updateUserPrefs.initiate({
          user_prefs: USER_PREFS.FOOD_MENU_FAVOURITES,
          action: USER_PREFS_ACTION_TYPES.UPDATE,
          data: args,
        })
      );
    }
  }
);
