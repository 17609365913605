import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Anchor as TAnchor, IAnchorProps as ITAnchorProps, isExternalUrl, parseExternalUrl } from 'taltech-styleguide';

// reuse this function when you want to pass it into other components that accept Anchor props (E.g. Logo, Header etc)
export const LinkBehaviour = React.forwardRef<HTMLAnchorElement, LinkProps>(({ to, children, ...rest }, ref) => {
  const url = typeof to === 'string' ? to : to?.pathname || '';
  // when external link then render plain <a> tag
  return isExternalUrl(url) ? (
    <a ref={ref} href={parseExternalUrl(url)} {...rest}>
      {children}
    </a>
  ) : (
    <Link ref={ref} to={to} {...rest}>
      {children}
    </Link>
  );
});
LinkBehaviour.displayName = 'LinkBehaviour';

export type IAnchorProps<C extends React.ElementType = typeof LinkBehaviour> = ITAnchorProps<C>;
/**
 * Wrap Anchor from styleguide with React router <Link> logic
 * Reuse this component when you want to render <Anchor> in JSX. NB! Do not pass this to other components link(s) props.
 */
export const Anchor = <C extends React.ElementType = typeof LinkBehaviour>(props: IAnchorProps<C>) => {
  return <TAnchor as={LinkBehaviour} {...props} />;
};
