import React, { ComponentType } from 'react';

import { generalApi } from '../../common/store/GeneralApiStore';
import store from '../../common/store/storeConfig';

/**
 * React.lazy with a fallback that checks if the current version matches latest. If it doesn't then reload the app
 * Required to handle users that are using the old version (haven't done a reload) and a new release is uploaded.
 */
export function lazyComponent(component: () => Promise<{ default: ComponentType<any> }>) {
  return React.lazy(
    () =>
      new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
        component()
          .then(resolve)
          .catch(async (e) => {
            console.error('Problem loading chunk', e);

            try {
              const response = await store.dispatch(
                generalApi.endpoints.getAppVersion.initiate(undefined, { subscribe: false, forceRefetch: true })
              ); // Version is injected into manifest.json during build script
              const data = response?.data;

              // when current app version doesn't match the latest one, then do a reload
              if (data?.app_version && data?.app_version !== process.env.REACT_APP_VERSION) {
                console.warn(
                  `Versions didn't match (current=${process.env.REACT_APP_VERSION}, latest=${data?.app_version}). Reloading page...`
                );
                window.location.reload();
              } else if (data?.app_version) {
                console.log(`Versions match (current=${process.env.REACT_APP_VERSION}, latest=${data?.app_version})`);

                reject(e);
              } else {
                console.log(`No version info in manifest.json`);
              }
            } catch (catchError) {
              reject(catchError);
            }
          });
      })
  );
}
