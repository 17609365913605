import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/browser';
import { toast } from 'taltech-styleguide';

import { acquireAccessToken } from '../auth/azureAuth';
import { SENTRY_API_ERRORS, SENTRY_TAGS } from '../constants/sentry';

interface QueryExtraOptions {
  /**
   * Request should be anonymous (Don't add authorization headers)
   */
  anonymous?: boolean;
  /**
   * Callback that gets called when a request fails
   */
  onError?: (error: FetchBaseQueryError) => void;
  /**
   * Makes it easier to show an error toast
   */
  onErrorString?: (error: FetchBaseQueryError) => string | undefined;
  /**
   * Makes it easier to show an success message toast
   */
  onSuccessString?: (data: unknown) => string | undefined;
  /**
   * Add extra scopes to the token request
   */
  scopes?: string[];
  /**
   * Do a window reload after successful request
   */
  reload?: boolean;
}

/**
 * Modify fetchBasedQuery with our own headers and error management
 */
export const globalBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, QueryExtraOptions> = async (
  args,
  api,
  extraOptions
) => {
  // send request and attach headers
  const result = await fetchBaseQuery({
    prepareHeaders: async (headers) => {
      const token = await acquireAccessToken(
        extraOptions?.scopes?.length ? { scopes: extraOptions?.scopes } : undefined
      );

      if (token && !extraOptions?.anonymous) {
        headers.set('authorization', `Bearer ${token.accessToken}`);
      }

      return headers;
    },
  })(args, api, extraOptions);

  // if there was a parsing error then send capture sentry exception.
  // most likely when API doesn't return json (E.g. there was a unhandled PHP error)
  if (result.error?.status === 'PARSING_ERROR') {
    console.error(`${result?.meta?.request.url} parse error`, result.error?.error);
    Sentry.withScope((scope) => {
      scope.setTag(SENTRY_TAGS.API_ERROR, SENTRY_API_ERRORS.NOT_JSON);
      scope.setExtra('api_endpoint', result?.meta?.request.url);
      scope.setExtra('api_data', (result.error?.data as string)?.slice(0, 100));
      Sentry.captureException(result.error);
    });
  }

  // pass error to a callback
  if (result.error && extraOptions?.onError) {
    extraOptions.onError(result.error);
  }

  // calls this error when request fails
  if (result.error && extraOptions?.onErrorString) {
    const errorString = extraOptions.onErrorString(result.error);

    if (errorString) {
      toast.error(errorString);
    }
  }

  // calls this success when request succeeds
  if (result.data && !result.error && extraOptions?.onSuccessString) {
    const successString = extraOptions?.onSuccessString(result.data);

    if (successString) {
      toast.success(successString);
    }
  }

  if (!result.error && extraOptions?.reload) {
    window.location.reload();
  }

  return result;
};

// Global query cache tags
// TODO remove module specific tags like powerbi and rooms. see ExpensesApiStore.ts for an example
export enum CACHE_TAGS {
  WHO_AM_I = 'WhoAmI',
  FOOTER_BOOKMARKS = 'FooterBookmarks',
  POWERBI_REPORTS = 'PowerbiReports',
  POWERBI_FAVOURITE_REPORTS = 'PowerbiFavouriteReports',
  POWERBI_MY_REPORTS = 'PowerbiMyReports',
  POWERBI_REPORT = 'PowerbiReport',
  POWERBI_CATEGORIES = 'PowerbiCategories',
  ROOMS_LIST = 'RoomsList',
  ROOM_DETAILS = 'RoomDetails',
  TRAININGS_REGISTERED = 'TrainingsRegistered',
  TRAININGS_ON_WAITING_LIST_REGISTERED = 'TrainingsOnWaitingListRegistered',
  TRAININGS_WAITING_LIST = 'TrainingsWaitingList',
  TRAININGS_LIST = 'TrainingsList',
}

// base redux query api slice. we extend this in other modules
export const apiBase = createApi({
  reducerPath: 'api',
  baseQuery: globalBaseQuery,
  refetchOnMountOrArgChange: 30,
  tagTypes: Object.values(CACHE_TAGS),
  endpoints: () => ({}),
});
