export function blobToFileDownload(blob: any, fileName: string, fileFormat?: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}${fileFormat ? `.${fileFormat}` : ''}`;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function convertFileToBase64(file: File): Promise<string | null | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
